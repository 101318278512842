import React from "react";
import PropTypes from "proptypes";
import { ButtonIconOnly } from "./../../components";
import "./PlayerToInvite.css";

const PlayerToInvite = ({ description, removeItem, title, value }) => (
  <li className="c-player-to-invite">
    <div>
      <strong className="c-player-to-invite__title">{title}</strong>
      <span className="c-player-to-invite__description">{description}</span>
    </div>
    <ButtonIconOnly
      icon="id_trash_icon"
      text="Remove item"
      value={value}
      onClick={removeItem}
    />
  </li>
);

PlayerToInvite.propTypes = {
  description: PropTypes.string,
  removeItem: PropTypes.func,
  title: PropTypes.string,
  value: PropTypes.string
};

export default PlayerToInvite;
