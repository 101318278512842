import React from "react";
import { CheckBoxGroup } from "./../../components";
import "./CheckBoxTags.css";

const CheckBoxTags = ({ ...props }) => (
  <CheckBoxGroup
    {...props}
    checkBoxClassName="h-hide-visually"
    checkBoxLabelClassName="c-checkbox-tag"
  />
);

export default CheckBoxTags;
