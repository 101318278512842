import React from "react";
import Modal from "react-modal";
import PropTypes from "prop-types";
import { ButtonIconOnly } from "./../../components";
import "./Dialog.css";

Modal.setAppElement("#root");

const Dialog = ({
  children,
  contentLabel,
  hasCloseButton = true,
  isOpen,
  onRequestClose,
  openModal,
  title
}) => (
  <Modal
    isOpen={isOpen}
    onAfterOpen={openModal}
    onRequestClose={onRequestClose}
    contentLabel={contentLabel}
    style={{
      overlay: {
        zIndex: "9999",
        backgroundColor: "rgba(0, 0, 0, 0.75)",
        display: "flex",
        justifyContent: "center"
      },
      content: {
        border: "1px solid var(--g-color-blue-500)",
        backgroundColor: "var(--g-color-blue-800)",
        bottom: "auto",
        margin: "0 auto",
        minWidth: "20rem",
        overflow: "visible",
        top: "var(--g-spacing-2x-large)",
        left: "auto",
        right: "auto"
      }
    }}
  >
    <div className="c-dialog-header">
      <h3 className="c-dialog-title">{title}</h3>

      {hasCloseButton && (
        <ButtonIconOnly
          icon="id_close_icon"
          onClick={onRequestClose}
          text="Close"
        />
      )}
    </div>
    {children}
  </Modal>
);

Dialog.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  contentLabel: PropTypes.string,
  isOpen: PropTypes.bool,
  onRequestClose: PropTypes.func,
  openModal: PropTypes.func,
  title: PropTypes.string
};

export default Dialog;
