import objectMap from "./../object/objectMap";

const objFromArray = valueFunc => arr => {
  const res = {};
  arr.forEach(item => (res[item] = valueFunc(item)));
  return res;
};

const setItem = (key, value) =>
  localStorage.setItem(key, JSON.stringify(value));

const getItem = key => JSON.parse(localStorage.getItem(key));

const clearItem = key => localStorage.removeItem(key);

const setLocalStorageItems = items => objectMap(setItem)(items);

const getLocalStorageItems = (...keys) => objFromArray(getItem)(keys);

const clearLocalStorageItems = (...keys) => objFromArray(clearItem)(keys);

export { clearLocalStorageItems, setLocalStorageItems, getLocalStorageItems };
