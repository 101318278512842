import { getMod, roll } from "./index";

const rollStat = (statName, base, modifier = 0) => {
  const result = roll(20);
  const mod = getMod(base);

  return `${statName}: ${result + mod + modifier}`;
};

export default rollStat;
