import React from "react";
import PropTypes from "prop-types";
import "./HomePanel.css";

const HomePanel = ({ children }) => (
  <ul className="c-home-panel">{children}</ul>
);

HomePanel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default HomePanel;
