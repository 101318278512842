const URLS = Object.freeze({
  adventure: "/app/adventure/:adventureId/:channelId",
  adventureRoot: "/app/adventure",
  app: "/app",
  createAdventure: "/app/create-adventure",
  forgotPassword: "/forgot-password",
  home: "/app/home",
  joinAdventure: "/app/join-adventure/:adventureId",
  joinAdventureRoot: "/app/join-adventure",
  login: "/login",
  notVerified: "/app/not-verified",
  register: "/register",
  resetPassword: "/reset-password",
  subscriptions: "/app/subscriptions",
  verifyEmail: "/verify-email",
  welcome: "/app/welcome"
});

export default URLS;
