import React, { Fragment } from "react";
import {
  Container,
  CopyText,
  Header,
  Spacing,
  SubscriptionList
} from "./../../components";

const Subscriptions = () => (
  <Fragment>
    <Header title="Subscriptions" />
    <Container>
      <Spacing>
        <CopyText>
          <p>Change your subscription plan here</p>
        </CopyText>
      </Spacing>
      <SubscriptionList />
    </Container>
  </Fragment>
);

export default Subscriptions;
