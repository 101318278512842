// Utils
import generateViewPortMap from "./generateViewPortMap";
import { getFirstTruthyMapKey } from "./../object";

/**
 * Provides a safe direction for a target element to be rendered favouring passed in preferences.
 * @param {Node} target - The item to be place onto the viewport.
 * @param {Node} trigger - The item it is to be placed relative to.
 * @param {Node} viewport - The document window.
 * @param {Array} preferred - A list of directions in order of preference.
 * @param {Object} offsets - Gutter between viewpoint and target element, margin between trigger
 * and target.
 * @return {string} - A safe direction to display the target.
 */
const getViewPortSafeDirection = (target, trigger, viewport, preferred) => {
  const viewPortMap = generateViewPortMap(target, trigger, viewport);

  return (
    preferred.find(item => viewPortMap[item]()) ||
    getFirstTruthyMapKey(viewPortMap)
  );
};

export default getViewPortSafeDirection;
