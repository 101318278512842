import React from "react";
import PropTypes from "prop-types";

const Form = ({ children, ...props }) => <form {...props}>{children}</form>;

Form.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default Form;
