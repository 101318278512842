import React from "react";
import PropTypes from "proptypes";
import classNames from "classnames";
import "./SideBar.css";

const SideBar = ({ children, isVisible, side }) => {
  const classes = classNames({
    "c-sidebar": true,
    [`c-sidebar--${side}`]: side,
    "is-active": isVisible
  });
  return <div className={classes}>{children}</div>;
};

SideBar.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  isVisible: PropTypes.bool,
  side: PropTypes.string
};

export default SideBar;
