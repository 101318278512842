import React from "react";
import PropTypes from "prop-types";
import { timestampToHourMins } from "./../../utils";

const DateFormat = ({ isHidden, timestamp }) => {
  return (
    <time style={{ display: "none" }} dateTime={timestamp}>
      {timestampToHourMins(timestamp)}
    </time>
  );
};

DateFormat.propTypes = {
  isHidden: PropTypes.bool,
  timestamp: PropTypes.string
};

export default DateFormat;
