import React from "react";
import { API } from "./../../../../utils";
import { Spacing } from "./../../../../components";
import EditAdventureDetailsForm from "./../../../EditAdventureDetailsForm";

const DMSettingsTab = ({ adventureId }) => {
  const handleSubmit = formState => {
    API.post("adventures", Object.assign(formState));
  };
  return (
    <Spacing>
      <EditAdventureDetailsForm
        handleSubmit={handleSubmit}
        adventureId={adventureId}
      />
    </Spacing>
  );
};

export default DMSettingsTab;
