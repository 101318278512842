import React from "react";
import PropTypes from "prop-types";

/**
 * Used to insert an SVG icon into other components.
 */
const Icon = ({ className, hasAriaHidden = true, id }) => (
  <svg aria-hidden={hasAriaHidden} className={className}>
    <use xlinkHref={`#${id}`} />
  </svg>
);

Icon.propTypes = {
  className: PropTypes.string,
  hasAriaHidden: PropTypes.bool,
  id: PropTypes.string
};

export default Icon;
