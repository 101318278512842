import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import { useKindeAuth } from "kinde-auth-react";
import {
  Container,
  Dropdown,
  ListHeader,
  PlayersList,
  SearchField,
  Suggestion,
} from "components";
import { debounce, isEmailValid, API } from "utils";
import {
  pendingInvite,
  findExistingPlayer,
  inviteNewPlayer,
} from "consts/strings";
import "./InvitePlayer.css";
import { KEY_CODES } from "consts";

const InvitePlayer = ({ adventureId, handleChange, playerCount }) => {
  const typeAheadName = "query";
  const { first_name: username } = useKindeAuth();

  const [playersToInvite, setPlayersToInvite] = useState([]);
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);

  const passValueUp = (players) => {
    handleChange(players);
  };

  const handleAdd = (players) => {
    setPlayersToInvite(players);
    passValueUp(players);
    setQuery("");
    setResults([]);
  };

  const addToPlayers = (e) => {
    const button = e.target;
    const { secondaryValue } = button.dataset;
    const players = playersToInvite.concat([
      {
        name: button.value,
        email: secondaryValue,
      },
    ]);
    handleAdd(players);
  };

  const removeFromPlayers = (e) => {
    const players = playersToInvite.filter((p) => p.email !== e.target.value);
    setPlayersToInvite(players);
    passValueUp(players);
  };

  const handleEnter = (e) => {
    const el = e.target;
    if (e.keyCode === KEY_CODES.enter) {
      e.preventDefault();
      if (isEmailValid(el.value)) {
        const players = playersToInvite.concat([
          {
            name: null,
            email: el.value,
          },
        ]);
        handleAdd(players);
      }
    }
  };

  const findPlayers = (e) => {
    const { value } = e.target;
    setQuery(value);
    debounce(() => {
      API.post("find_players", {
        query: value,
        adventureId,
      }).then((res) => {
        setResults(res.data.data);
      });
    }, 250)();
  };

  const foundMatch = (person) =>
    (person.name && person.name.toLowerCase()) !== username.toLowerCase();

  return (
    <>
      <ListHeader>
        <span className="c-input-field__label">{`Players (${playersToInvite.length} of ${playerCount})`}</span>

        {playersToInvite.length !== playerCount && (
          <Dropdown buttonText="Add">
            <Container isSmall>
              <SearchField
                name={typeAheadName}
                value={query}
                autoFocus={true}
                onChange={findPlayers}
                onKeyDown={handleEnter}
                placeholder="Invite with email/username"
              />
            </Container>
            {results.length > 0 ? (
              <ul className="c-invite-player__result-container">
                {results
                  .filter((r) => foundMatch(r))
                  .map((r, i) => (
                    <Fragment key={i}>
                      {!playersToInvite.some((p) => p.email === r.login) && (
                        <Suggestion
                          title={r.name || r.login}
                          description={r.name ? r.login : pendingInvite}
                          handleClick={addToPlayers}
                          value={r.name}
                          secondaryValue={r.login}
                        />
                      )}
                    </Fragment>
                  ))}
              </ul>
            ) : (
              <>
                {(isEmailValid(query) &&
                  !playersToInvite.some((r) => r.email === query) && (
                    <Suggestion
                      title={query}
                      handleClick={addToPlayers}
                      secondaryValue={query}
                    />
                  )) || (
                  <Suggestion
                    description={
                      (query && inviteNewPlayer) || findExistingPlayer
                    }
                  />
                )}
              </>
            )}
          </Dropdown>
        )}
      </ListHeader>
      {Boolean(playersToInvite.length) ? (
        <PlayersList data={playersToInvite} removeItem={removeFromPlayers} />
      ) : (
        "Add some players using the plus icon above"
      )}
    </>
  );
};

InvitePlayer.propTypes = {
  adventureId: PropTypes.string,
  handleChange: PropTypes.func,
  playerCount: PropTypes.number,
};

export default InvitePlayer;
