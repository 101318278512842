import React, { useState, useEffect } from "react";
import PropTypes from "proptypes";
import { API, debounce } from "utils";
import {
  CheckBoxTags,
  Fieldset,
  Form,
  InputField,
  Loading,
  RadioButtonGroup,
  Spacing
} from "components";

const EditAdventureDetailsForm = ({ adventureId }) => {
  const [values, setValues] = useState({
    new_name: "",
    types: [],
    new_adventure_status: "1",
    new_types: [],
    new_description: "",
    isLoading: true
  });
  const [isLoading, setIsLoading] = useState();
  const [types, setTypes] = useState([]);

  // componentDidMount
  useEffect(() => {
    const getData = async () => {
      const typeRes = await API.get("get_dictionary/T1");
      const res = await API.get(`adventures/${adventureId}`);
      const { adventure, tags } = res.data;
      console.log(res.data);
      setValues({
        new_name: adventure.name,
        new_description: adventure.description,
        new_adventure_status: String(adventure.adventure_status_id),
        new_types: tags.map(tag => tag.adventure_type_id)
      });
      setIsLoading(false);
      setTypes(typeRes.data.data || []);
    };
    getData();
  }, [adventureId]);

  const saveValue = (url, name, value, debounceLength = 0) => {
    setValues({ ...values, [name]: value });
    debounce(() => {
      API.post(url, {
        id: adventureId,
        [name]: value,
        field: name
      });
    }, debounceLength)();
  };

  const updateFieldWithDebounce = (field, url) => {
    const { value, name } = field;
    saveValue(url, name, value, 500);
  };

  const handleChange = e => {
    let value;
    if (e.target.type === "checkbox") {
      const array = values[e.target.name];
      const val = Number(e.target.value);
      const idx = array.indexOf(val);
      value =
        idx !== -1
          ? array.filter(item => String(item) !== String(val))
          : array.concat([val]);
      saveValue("update_adventure", e.target.name, value);
    } else {
      value = e.target.value;
      updateFieldWithDebounce(e.target, "update_adventure");
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Form autoComplete="off">
      <Fieldset title="Settings">
        <Spacing>
          <InputField
            name="new_name"
            onChange={handleChange}
            value={values.new_name}
            labelText="Adventure name"
            required
          />
        </Spacing>
        <Spacing>
          <InputField
            isMultiline
            name="new_description"
            onChange={handleChange}
            value={values.new_description}
            labelText="Description"
          />
        </Spacing>
      </Fieldset>
      <Spacing size="2x-large">
        <CheckBoxTags
          data={types}
          isInline
          name="new_types"
          onChange={handleChange}
          selectedItems={values.new_types}
          title="Tags"
        />
      </Spacing>
      <Spacing>
        <RadioButtonGroup
          data={[
            { id: "1", name: 'Public - Discoverable via "Find Adventure"' },
            { id: "2", name: "Private - Only available to people I invite" }
          ]}
          name="new_adventure_status"
          onChange={handleChange}
          selectedId={values.new_adventure_status}
          title="Fill available player slots"
        />
      </Spacing>
    </Form>
  );
};

EditAdventureDetailsForm.propTypes = {
  adventureId: PropTypes.number
};
export default EditAdventureDetailsForm;
