import React from "react";
import PropTypes from "prop-types";
import { getMod } from "./../../utils";
import "./SkillItem.css";

const SkillItem = ({ name, rollStat, value }) => (
  <li className="c-skill-item">
    <div className="c-skill-item__name">{name}</div>
    <div className="c-skill-item__value">{getMod(value) || ""}</div>

    <button
      onClick={() => rollStat(name, value)}
      type="submit"
      className="c-stat__button"
    />
  </li>
);

SkillItem.propTypes = {
  name: PropTypes.string,
  rollStat: PropTypes.func,
  value: PropTypes.number
};

export default SkillItem;
