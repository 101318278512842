import React from "react";
import PropTypes from "prop-types";
import { Icon, Label } from "./../../components";
import "./SearchField.css";

const SearchField = ({
  labelText = "Search",
  isLabelHidden = true,
  name,
  type,
  autoFocus = false,
  value = "",
  ...props
}) => {
  return (
    <div className="c-search-field">
      <Label forId={`id_${name}`} isHidden={isLabelHidden} text={labelText} />

      <Icon className="c-search-field__icon" id="id_search_icon" />
      <input
        {...props}
        autoComplete="off"
        className="c-search-field__input"
        name={name}
        type="search"
        value={value}
        autoFocus={autoFocus}
      />
    </div>
  );
};

SearchField.propTypes = {
  isLabelHidden: PropTypes.bool,
  labelText: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  autoFocus: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default SearchField;
