import React from "react";
import PropTypes from "prop-types";
import { Heading, Spacing } from "./../../components";

const Fieldset = ({ children, title }) => (
  <fieldset>
    <Spacing size="large">
      <legend>
        <Heading tag="span" text={title} />
      </legend>
    </Spacing>
    {children}
  </fieldset>
);

Fieldset.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  title: PropTypes.string
};

export default Fieldset;
