import { Children, useEffect } from "react";
import PropTypes from "proptypes";
import { KEY_CODES } from "./../../consts";

/**
 * Closes a child component when pressing the ESC key.
 *
 * A child component passes in the function responsible for closing itself via the `callback`
 * property.
 */
const EscapeKeyClose = ({ children, callback }) => {
  // componentDidMount
  useEffect(() => {
    const invokeCallback = e => {
      if (e.keyCode === KEY_CODES.escape) {
        callback();
      }
    };

    const bindEvents = () => {
      document.addEventListener("keydown", invokeCallback);
    };

    const unbindEvents = () => {
      document.removeEventListener("keydown", invokeCallback);
    };
    bindEvents();
    return unbindEvents;
  }, [callback]);

  return Children.only(children);
};

EscapeKeyClose.propTypes = {
  /**
   * A callback function that is triggered from a child component.
   */
  callback: PropTypes.func.isRequired,
  /**
   * Child elements.
   */
  children: PropTypes.node.isRequired
};

EscapeKeyClose.defaultProps = {
  callback: () => {}
};

export default EscapeKeyClose;
