import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useKindeAuth } from "kinde-auth-react";
import {
  Badge,
  ButtonIconOnly,
  ChangePasswordForm,
  DetailsList,
  DetailsListItem,
  Dialog,
  Heading,
  Notification,
  Spacing,
} from "components";
import { API } from "utils";
import { URLS } from "consts";

const UserSettings = () => {
  const { first_name: username, email, isPremium } = useKindeAuth();

  const [newPassword, setNewPassword] = useState("");
  const [isSaved, setIsSaved] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);

  const closeModal = () => setIsModalOpen(false);

  const handleChange = (e) => setNewPassword(e.target.value);

  const handleSubmit = (e) => {
    e.preventDefault();
    API.put("change_password", { new_password: newPassword });
    setNewPassword("");
    setIsSaved(true);
    setIsModalOpen(false);
  };

  // componentDidMount
  useEffect(() => {
    setIsSaved(false);
  }, []);

  return (
    <>
      <Heading text="Settings" spacing="large" />

      {isSaved && (
        <Spacing size="x-large">
          <Notification
            type="success"
            title="Nice one!"
            text={<p>Your new password has been saved.</p>}
          />
        </Spacing>
      )}
      <DetailsList>
        <DetailsListItem title="Username" description={username} />
        <DetailsListItem title="Email" description={email} />
        <DetailsListItem
          title="Password"
          description={
            <>
              ●●●●●●●●{" "}
              <ButtonIconOnly
                icon="id_edit_icon"
                onClick={openModal}
                text="Close"
              />
            </>
          }
        />
        <DetailsListItem
          title="Plan"
          description={
            isPremium ? (
              <Badge text="Premium" />
            ) : (
              <>
                Free <Link to={URLS.subscriptions}>[Upgrade]</Link>
              </>
            )
          }
        />
      </DetailsList>

      <Dialog
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Password Dialog"
        title="Change your password"
      >
        <ChangePasswordForm
          handleSubmit={handleSubmit}
          title=""
          handleChange={handleChange}
          value={newPassword}
          inputLabelText="New password"
          buttonText="Save password"
        />
      </Dialog>
    </>
  );
};

export default UserSettings;
