import React, { Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./SideBarListItem.css";

const SideBarListItem = ({
  amendStat,
  label,
  isHeart,
  isShield,
  isValueNumber = true,
  rollStat,
  value
}) => {
  const classes = classNames({
    "c-player-profile__value": isValueNumber,
    "c-player-profile__value--shield": isShield,
    "c-player-profile__value--heart": isHeart
  });

  return (
    <div className="c-sidebar--right-list__item">
      <dt className="c-player-profile__title">{label}</dt>
      <div className={classes}>
        <dd>
          {value}
          {amendStat && (
            <Fragment>
              <button
                className="c-player-profile__button c-player-profile__button--left"
                onClick={() => amendStat(-1)}
              >
                -
              </button>
              <button
                className="c-player-profile__button c-player-profile__button--right"
                onClick={() => amendStat(+1)}
              >
                +
              </button>
            </Fragment>
          )}
          {rollStat && (
            <Fragment>
              <button
                onClick={rollStat}
                type="submit"
                className="c-stat__button h-cover-button"
              />
              <div className="c-stat__roll">roll</div>
            </Fragment>
          )}
        </dd>
      </div>
    </div>
  );
};

SideBarListItem.propTypes = {
  amendStat: PropTypes.func,
  isValueNumber: PropTypes.bool,
  isHeart: PropTypes.bool,
  isShield: PropTypes.bool,
  label: PropTypes.string,
  rollStat: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default SideBarListItem;
