import React, { Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./Avatar.css";

const Image = ({ name, url }) => (
  <img
    className="c-avatar__image"
    src={`${process.env.PUBLIC_URL}/images/${url}`}
    alt={`Avatar for ${name}`}
  />
);

const Avatar = ({ characterName, handleClick, imageUrl, isLarge }) => {
  const classes = classNames({
    "c-avatar": true,
    "c-avatar--large": isLarge
  });
  return (
    <Fragment>
      {handleClick ? (
        <button
          className={classes}
          type="button"
          onClick={handleClick}
          aria-label="View profile"
        >
          <Image url={imageUrl} name={characterName} />
        </button>
      ) : (
        <span className={classes}>
          <Image url={imageUrl} name={characterName} />
        </span>
      )}
    </Fragment>
  );
};

Avatar.propTypes = {
  characterName: PropTypes.string,
  handleClick: PropTypes.func,
  imageUrl: PropTypes.string,
  isLarge: PropTypes.bool
};

export default Avatar;
