import React, { Fragment } from "react";
import { Heading, SideBarListItem } from "./../../../../components";
import { getMod } from "./../../../../utils";

const CharacterDetailsTab = ({
  amendGold,
  amendHitPoints,
  character,
  currentHealth,
  currentGold,
  equipment,
  stats,
  rollStat,
  weaponPrimaryHand,
  weaponOffHand
}) => {
  return (
    stats &&
    stats.length && (
      <Fragment>
        <Heading level={3} spacing="small" text="Details" />
        <dl className="c-sidebar--right-list">
          <SideBarListItem
            label="HP"
            value={currentHealth}
            amendStat={amendHitPoints}
            isHeart={true}
          />
          <SideBarListItem
            label="AC"
            value={character.base_armour}
            isShield={true}
          />
          <SideBarListItem
            label="Initiative"
            value={getMod(stats[1].value)}
            isShield={true}
            rollStat={() => rollStat("Initiative", stats[1].value)}
          />
        </dl>
        <Heading level={3} spacing="small" text="Saving Throws" />
        <dl className="c-sidebar--right-list">
          <SideBarListItem
            label="FORT"
            value={getMod(stats[3].value) + character.fortitude_save}
            isShield={true}
            rollStat={() =>
              rollStat(
                "Fortitude",
                getMod(stats[3].value) + character.fortitude_save
              )
            }
          />
          <SideBarListItem
            label="REF"
            value={getMod(stats[1].value) + character.reflex_save}
            isShield={true}
            rollStat={() =>
              rollStat("Reflex", getMod(stats[1].value) + character.reflex_save)
            }
          />
          <SideBarListItem
            label="WILL"
            value={getMod(stats[4].value) + character.will_save}
            isShield={true}
            rollStat={() =>
              rollStat("Will", getMod(stats[4].value) + character.will_save)
            }
          />
        </dl>
        <Heading level={3} spacing="small" text="Abilities" />
        <dl className="c-sidebar--right-list">
          {stats.map((stat, i) => (
            <SideBarListItem
              key={i}
              label={stat.code}
              value={stat.value}
              rollStat={() => rollStat(stat.name, stat.value)}
            />
          ))}
        </dl>
        <Heading level={3} spacing="small" text="Combat" />
        <dl className="c-sidebar--right-list">
          <SideBarListItem
            label="Grapple"
            value={getMod(stats[0].value) + character.base_attack_bonus}
            rollStat={() =>
              rollStat(
                "Grapple",
                getMod(stats[0].value) + character.base_attack_bonus
              )
            }
          />
          {weaponPrimaryHand && (
            <Fragment>
              <h3>{weaponPrimaryHand.name}</h3>
              <SideBarListItem
                label="To hit"
                value={getMod(stats[0].value) + character.base_attack_bonus}
                rollStat={() =>
                  rollStat(
                    "To hit",
                    getMod(stats[0].value) + character.base_attack_bonus
                  )
                }
              />
              <SideBarListItem
                label="Damage"
                value={getMod(stats[0].value) + character.base_attack_bonus}
                rollStat={() =>
                  rollStat(
                    "Damage",
                    getMod(stats[0].value) + character.base_attack_bonus
                  )
                }
              />
            </Fragment>
          )}
        </dl>
      </Fragment>
    )
  );
};

export default CharacterDetailsTab;
