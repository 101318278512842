import React from "react";
import PropTypes from "prop-types";
import "./SideBarHeader.css";

const SideBarHeader = ({ children }) => (
  <div className="c-side-bar-header">{children}</div>
);

SideBarHeader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default SideBarHeader;
