import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Button, Form } from "components";
import { API } from "utils";
import "./HelpTip.css";

const HelpTip = ({ children, helpTipId, shouldShow, text }) => {
  const [isFadeOut, setIsFadeOut] = useState(false);

  const classes = classNames({
    "c-help-tip": true,
    "is-fade-out": isFadeOut
  });

  const onSubmit = async e => {
    e.preventDefault();
    setIsFadeOut(true);
    try {
      await API.post(`help_tips/${helpTipId}`);
    } catch (err) {
      console.log(err);
    }
  };

  return shouldShow ? (
    <div className="h-positioning-context">
      {children}
      <div className={classes}>
        <p className="h-spacing">{text}</p>
        <Form onSubmit={onSubmit}>
          <Button
            text="Ok, got it"
            value={helpTipId}
            isSquashed
            type="submit"
          />
        </Form>
      </div>
    </div>
  ) : (
    <>{children}</>
  );
};

HelpTip.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  helpTipId: PropTypes.string,
  should: PropTypes.bool,
  text: PropTypes.string
};

export default HelpTip;
