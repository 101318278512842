import React, { useEffect } from "react";
import { useKindeAuth } from "kinde-auth-react";
import {
  Adventure,
  CreateAdventure,
  JoinAdventure,
  Home,
  Subscriptions,
  Welcome,
} from "../index";
import { URLS } from "consts";
import { API } from "utils";

import { Route, Redirect } from "react-router-dom";

const AppRoutes = (props) => {
  const { user, isAuthenticated, getToken } = useKindeAuth();

  useEffect(() => {
    (async () => {
      let isSubscribed = true;
      if (user && isSubscribed) {
        try {
          const { preferred_email, first_name } = user;

          const accessToken = await getToken();
          await API.post(
            "handle_user",
            {
              email: preferred_email,
              firstName: first_name,
            },
            {
              headers: {
                // Add the Authorization header to the existing headers
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );
          return () => (isSubscribed = false);
        } catch (err) {
          console.log(err);
        }
      }
    })();
  }, [getToken, user]);

  if (!isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: "/",
          state: { to: props.location.pathname },
        }}
      />
    );
  }

  if (!user.first_name && props.location.pathname !== URLS.welcome) {
    return (
      <Redirect
        to={{
          pathname: URLS.welcome,
          state: { to: props.location.pathname },
        }}
      />
    );
  }

  return (
    <>
      <Route path={URLS.welcome} component={Welcome} />
      <Route path={URLS.home} component={Home} />
      <Route path={URLS.createAdventure} component={CreateAdventure} />
      <Route path={URLS.joinAdventure} component={JoinAdventure} />
      <Route path={URLS.adventure} component={Adventure} />
      <Route path={URLS.subscriptions} component={Subscriptions} />
    </>
  );
};

export default AppRoutes;
