import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./Button.css";

const Button = ({
  isFullWidth = false,
  isLoading = false,
  isSecondary = false,
  isSmall = false,
  isSquashed = false,
  text,
  type = "submit",
  ...props
}) => {
  const classes = classNames({
    "c-button": true,
    "c-button--full-width": isFullWidth,
    "c-button--primary": !isSecondary,
    "c-button--secondary": isSecondary,
    "c-button--small": isSmall,
    "c-button--squashed": isSquashed,
    "is-loading": isLoading
  });
  const Tag = props.href ? "a" : "button";
  return (
    <Tag className={classes} type={!props.href ? type : null} {...props}>
      <span className="c-button__label">{text}</span>
      <span aria-hidden="true" className="c-button__loader" />
    </Tag>
  );
};

Button.propTypes = {
  isFullWidth: PropTypes.bool,
  isLoading: PropTypes.bool,
  isSecondary: PropTypes.bool,
  isSmall: PropTypes.bool,
  isSquashed: PropTypes.bool,
  text: PropTypes.string,
  type: PropTypes.string
};

export default Button;
