import React, { useEffect, useState } from "react";
import {
  Avatar,
  ButtonIconOnly,
  Container,
  Heading,
  TabSet,
  SideBarHeader,
  SideBar,
  Spacing
} from "./../../../components";

import DMCharacterListTab from "./DMCharacterListTab";
import DMEquipmentTab from "./DMEquipmentTab";
import DMSkillsTab from "./DMSkillsTab";
import DMSettingsTab from "./DMSettingsTab";
import DMNotesTab from "./DMNotesTab";
import "./DMRightSidebar.css";
import { API } from "utils";

const DMRightSideBar = ({
  adventureId,
  adventureMaxPCs,
  hideSidebar,
  isVisible,
  requestRoll,
  notes,
  updateNotes,
  viewEquipmentDetails
}) => {
  const [adventureCharacters, setAdventureCharacters] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      try {
        const advCharRes = await API.get(`adventure_characters/${adventureId}`);
        setAdventureCharacters(advCharRes.data.data);

        setIsLoading(false);
      } catch (err) {
        console.log(err);
      }
    };
    getData();
  }, [adventureId]);

  return (
    <SideBar isVisible={isVisible} side="right">
      <SideBarHeader>
        <div className="h-align-right">
          <ButtonIconOnly
            icon="id_close_icon"
            onClick={hideSidebar}
            text="Close sidebar"
          />
        </div>
        <Container hasCenteredContent>
          <Spacing>
            <Avatar
              characterName="DM"
              imageUrl="portraits/dm.png"
              isLarge={true}
            />
          </Spacing>
          <Heading text="DM" isColored={true} />
        </Container>
      </SideBarHeader>
      <TabSet
        data={[
          {
            href: "character-list",
            label: "Characters",
            icon: "id_profile_icon",
            content: (
              <DMCharacterListTab
                adventureId={adventureId}
                characters={adventureCharacters}
                maxPCs={adventureMaxPCs}
              />
            )
          },
          {
            href: "skills-index",
            label: "Skills Index",
            icon: "id_skills_icon",
            content: <DMSkillsTab requestRoll={requestRoll} />
          },
          {
            href: "equipment-index",
            label: "Equipment Index",
            icon: "id_equipment_icon",
            content: <DMEquipmentTab />
          },
          {
            href: "dm-notes",
            label: "Notes",
            icon: "id_notes_icon",
            content: <DMNotesTab notes={notes} updateNotes={updateNotes} />
          },
          {
            href: "dm-settings",
            label: "Settings",
            icon: "id_cog_icon",
            content: <DMSettingsTab adventureId={adventureId} />
          }
        ]}
        defaultHref="character-list"
      />
    </SideBar>
  );
};

export default DMRightSideBar;
