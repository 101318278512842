import React, { useState, useEffect, useCallback } from "react";
import { useKindeAuth } from "kinde-auth-react";
import { Loading, MessageForm, MessageList } from "components";
import { getCommandType, API } from "utils";
import { commandFunctionMap } from "consts";
import { API_ROOT } from "config/config";

const messageTypes = ["roll", "message", "skill", "emote", "npc"];

const Channel = ({
  adventureId,
  channelId,
  character,
  abilityRollMessage,
  requestRollMessage,
  clearRequestForRoll,
  clearRollAbility,
}) => {
  const { first_name: username } = useKindeAuth();

  const [channel, setChannel] = useState({});
  const [newMessage, setNewMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [chatEvents] = useState(
    () =>
      new EventSource(`${API_ROOT}v1/chat/channel-${channelId}`, {
        withCredentials: true,
      })
  );
  const showBrowserNotification = (res) => {
    if ("Notification" in window) {
      const options = {
        icon: "/android-chrome-192x192.png",
        badge: "/android-chrome-192x192.png",
        image: `/images/${res.avatar}`,
        body: res.content,
      };
      if (document.hidden) {
        new Notification(`New message by ${res.author}`, options);
      }
    }
  };

  // Initial data load
  useEffect(() => {
    const getData = async () => {
      try {
        const channelRes = await API.get(`channels/${channelId}`);
        const messagesRes = await API.get(`messages/${channelId}`);
        setChannel(channelRes.data.data || {});
        setMessages(messagesRes.data.data || []);
        setIsLoading(false);
      } catch (err) {
        console.error(err);
      }
    };
    getData();
  }, [channelId]);

  useEffect(() => {
    chatEvents.addEventListener("message", function (event) {
      const data = JSON.parse(event.data);
      setMessages((messages) => messages.concat([data]));
      showBrowserNotification(data);
    });

    return () => chatEvents.close();
  }, [chatEvents]);

  const postMessage = useCallback(
    (content, type) => {
      const data = {
        adventure_id: adventureId,
        character_name: character.name,
        avatar: character.avatar,
        character_id: character.id,
        author: username,
        content,
        channel_id: channelId,
        created_on: String(new Date()),
        id:
          1000 *
          Math.floor(Math.random() * 20) *
          Math.floor(Math.random() * 20),
        type: messageTypes[type - 1],
        type_id: type,
      };
      API.post("messages", data);
      setMessages((messages) => messages.concat([data]));
      setNewMessage("");
    },
    [
      adventureId,
      channelId,
      character.avatar,
      character.id,
      character.name,
      username,
    ]
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    let xNewMessage = newMessage;
    let xType = 2;
    const commandType = getCommandType(newMessage);

    const characterType = () => (character.name === "DM" ? 1 : 2);
    const isRestricted = () =>
      !commandFunctionMap[commandType].characterTypes.includes(characterType());

    if (commandFunctionMap[commandType]) {
      if (isRestricted()) {
        xNewMessage = null;
        xType = null;
      } else {
        xNewMessage = commandFunctionMap[commandType].func(xNewMessage);
        xType = commandFunctionMap[commandType].id;
      }
    } else {
      console.log(
        `Command ${commandType} does not exist or has no special formatting required`
      );
    }

    postMessage(xNewMessage, xType);
  };

  const handleKeyUp = (e) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      handleSubmit(e);
    }
  };

  useEffect(() => {
    if (abilityRollMessage && abilityRollMessage.length) {
      postMessage(abilityRollMessage, 3);
      clearRollAbility();
    }
  }, [abilityRollMessage, clearRequestForRoll, clearRollAbility, postMessage]);

  useEffect(() => {
    if (requestRollMessage && requestRollMessage.length) {
      postMessage(requestRollMessage, 2);
      clearRequestForRoll();
    }
  }, [clearRequestForRoll, postMessage, requestRollMessage]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <MessageList
        data={messages || []}
        channelType={channel.channel_type_id}
        username={username}
      />

      <MessageForm
        channelId={channelId}
        channelType={channel.channel_type_id}
        onSubmit={handleSubmit}
        name="new_message"
        value={newMessage}
        onChange={(e) => setNewMessage(e.target.value)}
        onKeyUp={handleKeyUp}
        characterName={character.name}
      />
    </>
  );
};

export default Channel;
