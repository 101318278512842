import React from "react";
import PropTypes from "prop-types";
import { Label } from "./../../components";
import "./RadioButton.css";

const RadioButton = ({
  idx,
  isLabelHidden,
  labelText,
  name,
  radioButtonClassName,
  radioButtonLabelClassName,
  value = "",
  ...props
}) => (
  <p className="c-radio-button">
    <input
      {...props}
      id={`id_${name}_${idx}`}
      className={
        radioButtonClassName || "c-radio-button__input h-hide-visually"
      }
      type="radio"
      name={name}
      value={value}
    />
    <Label
      forId={`id_${name}_${idx}`}
      isHidden={isLabelHidden}
      text={labelText}
      className={radioButtonLabelClassName || "c-radio-button__label"}
    />
  </p>
);

RadioButton.propTypes = {
  idx: PropTypes.number,
  isLabelHidden: PropTypes.bool,
  labelText: PropTypes.string,
  name: PropTypes.string,
  radioButtonClassName: PropTypes.string,
  radioButtonLabelClassName: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default RadioButton;
