import React from "react";
import PropTypes from "prop-types";
import {
  AutoSuggest,
  Button,
  Form,
  InputField,
  Panel,
  SideBySide
} from "./../../components";
import "./MessageForm.css";

const MessageForm = ({
  channelId,
  characterName,
  channelType,
  onChange,
  name,
  value,
  ...props
}) => (
  <Form {...props} className="c-message-form">
    <input type="hidden" name="p_channel_id" value={channelId} />

    <Panel>
      {channelType !== 2 && (
        <AutoSuggest value={value} characterName={characterName} />
      )}
      <SideBySide>
        <InputField
          isLabelHidden={true}
          isMultiline
          isSlim
          name={name}
          onChange={onChange}
          value={value}
          labelText="Send message"
        />
        <Button text="Send" isSquashed={true} />
      </SideBySide>
    </Panel>
  </Form>
);

MessageForm.propTypes = {
  channelId: PropTypes.string,
  characterName: PropTypes.string,
  handleChange: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.string,
  username: PropTypes.string
};

export default MessageForm;
