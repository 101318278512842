import { makeRoll, getEmote, getNpcMsg, sendNudgeEmails } from "./../utils";

const commandFunctionMap = Object.freeze({
  roll: {
    id: 1,
    characterTypes: [1, 2],
    command: "/roll",
    desc: "Make a roll",
    params: "[dice] ([dice2] ...)",
    example: "/roll 1d20",
    func: msg => makeRoll(msg)
  },
  em: {
    id: 4,
    characterTypes: [1, 2],
    command: "/em",
    desc: "Make an emote",
    params: "[text]",
    example: "/em dashes towards the exit",
    func: msg => getEmote(msg)
  },
  npc: {
    id: 5,
    characterTypes: [1],
    command: "/npc",
    desc: "Speak as NPC",
    params: "[character] [message]",
    example: "/npc Grogg Hi there!",
    func: msg => getNpcMsg(msg)
  },
  nudge: {
    id: 6,
    characterTypes: [1, 2],
    command: "/nudge",
    desc: "Send email alert to player that they need to respond",
    params: "[character] (accepts multiple names or 'all' keyword)",
    example: "/nudge Troglor Grishnak",
    func: msg => sendNudgeEmails(msg)
  }
});

export default commandFunctionMap;
