import React, { useState } from "react";
import {
  AdventureListItem,
  CheckBoxTags,
  Heading,
  Loading,
  Spacing
} from "components";
import { toggleArrayItem } from "utils";
import { useDataApi } from "hooks";

const FindAdventure = () => {
  // TODO - Combine these into a hook that will fetch multiple?
  const { data, isLoading } = useDataApi("find_adventure", []);
  const { data: types, isLoading: isLoadingTypes } = useDataApi(
    "get_dictionary/T1",
    []
  );

  const [checkedTypes, setCheckedTypes] = useState([]);

  const onChange = e =>
    setCheckedTypes(toggleArrayItem(checkedTypes, Number(e.target.value)));

  const hasMatch = (arr1, arr2) =>
    arr1 && arr1.some(v1 => arr2.some(v2 => v1.id === v2));

  const isInSelectedTypes = adventureTypes => {
    const noneSelected = checkedTypes.length === 0;
    const allSelected = checkedTypes.length === types.length;
    // Excludes adventure if it does not include a selected checkbox
    return (
      noneSelected || allSelected || hasMatch(adventureTypes, checkedTypes)
    );
  };
  const adventureList = data.filter(adv => isInSelectedTypes(adv.types));

  return (
    <>
      <Heading level={1} spacing="large" text="Adventurers Wanted" />
      <Spacing>
        <CheckBoxTags
          data={types}
          isInline
          name="checkedTypes"
          onChange={onChange}
          selectedItems={checkedTypes}
          title="Tags"
        />
      </Spacing>
      {isLoading && isLoadingTypes ? (
        <Loading />
      ) : (
        <>
          {adventureList && adventureList.length ? (
            <ul>
              {adventureList.map(a => (
                <li key={a.id} className="h-spacing">
                  <AdventureListItem adventure={a} />
                </li>
              ))}
            </ul>
          ) : (
            <p>No adventures found</p>
          )}
        </>
      )}
    </>
  );
};

export default FindAdventure;
