import React from "react";
import PropTypes from "prop-types";
import "./DetailsList.css";

const DetailsList = ({ children }) => (
  <dl className="c-details-list">{children}</dl>
);

DetailsList.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default DetailsList;
