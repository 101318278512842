import React from "react";
import PropTypes from "proptypes";
import PlayerToInvite from "./PlayerToInvite";
import { pendingInvite } from "./../../consts/strings";

const PlayersList = ({ data, removeItem }) => (
  <ul>
    {data &&
      data.map((r, i) => (
        <PlayerToInvite
          title={r.name || r.email}
          key={i}
          description={r.name ? r.email : pendingInvite}
          removeItem={removeItem}
          value={r.email}
        />
      ))}
  </ul>
);

PlayersList.propTypes = {
  data: PropTypes.array,
  removeItem: PropTypes.func
};

export default PlayersList;
