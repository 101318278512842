import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./ButtonGroup.css";

const ButtonGroup = ({ children, isAlignedRight, isSpaceBetween }) => {
  const classes = classNames({
    "c-button-group": true,
    "c-button-group--space-between": isSpaceBetween,
    "c-button-group--right": isAlignedRight
  });
  return <div className={classes}>{children}</div>;
};

ButtonGroup.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  isAlignedRight: PropTypes.bool,
  isSpaceBetween: PropTypes.bool
};

export default ButtonGroup;
