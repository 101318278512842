import React from "react";
import PropTypes from "proptypes";
import classNames from "classnames";
import { Icon } from "./../../components";
import "./Notification.css";

const Notification = ({ title, body, text, type }) => {
  const classes = classNames({
    "c-notification": true,
    [`c-notification--${type}`]: type
  });
  return (
    <div className={classes}>
      <div className="c-notification__icon">
        <Icon
          id={type === "success" ? "id_tick_icon" : "id_warning_icon"}
          className="c-notification__svg"
        />
      </div>
      <div className="c-notification__copy">
        <h4 className="c-notification__heading">{title}</h4>
        <div className="c-notification__text">{text}</div>
      </div>
    </div>
  );
};

Notification.propTypes = {
  body: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string
};

export default Notification;
