import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import ClickOutside from "./../_ClickOutside";
import EscapeKeyClose from "./../_EscapeKeyClose";
import Icon from "./../Icon";
import { getViewPortSafeDirection } from "./../../utils";
import { KEY_CODES } from "./../../consts";
import "./Dropdown.css";

const Dropdown = ({
  handleOpen,
  handleClickOutside,
  _isViewportAware = true,
  preferredPosition = "bottom-center",
  buttonText,
  children,
  isOpenedByDownArrowKey
}) => {
  const ID_OPEN_BTN = "id_droppers";
  const ID_OVERLAY = "id_overs";

  const overlayEl = useRef(null);
  const buttonEl = useRef(null);

  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const [viewPortSafeDirectionClass, setViewPortSafeDirectionClass] = useState(
    preferredPosition
  );

  const open = () => {
    setIsPopoverVisible(true);
    if (handleOpen) handleOpen();
  };

  useEffect(() => {
    const handleOverlayPosition = () => {
      const pos = [
        preferredPosition,
        "bottom-left",
        "bottom-right",
        "top-left",
        "top-right"
      ];

      return !_isViewportAware
        ? preferredPosition
        : getViewPortSafeDirection(
            overlayEl.current,
            buttonEl.current,
            window,
            pos
          );
    };
    if (isPopoverVisible) {
      setViewPortSafeDirectionClass(handleOverlayPosition());
    }
  }, [_isViewportAware, isPopoverVisible, preferredPosition]);

  const close = () => setIsPopoverVisible(false);

  const clickOutside = () => {
    close();
    if (handleClickOutside) handleClickOutside();
  };

  const handleDownArrowKey = e => {
    if (e.keyCode === KEY_CODES.downArrow) {
      e.preventDefault();
      open();
    }
  };

  const classList = classNames({
    "c-dropdown": true,
    "c-dropdown--overlay-position-bottom-center": !viewPortSafeDirectionClass,
    // eslint-disable-next-line max-len
    [`c-dropdown--overlay-position-${viewPortSafeDirectionClass}`]: viewPortSafeDirectionClass
  });

  return (
    <div className={classList}>
      <button
        aria-controls={isPopoverVisible ? ID_OVERLAY : null}
        aria-expanded={isPopoverVisible}
        aria-haspopup
        id={ID_OPEN_BTN}
        type="button"
        onClick={isPopoverVisible ? close : open}
        onKeyDown={isOpenedByDownArrowKey ? handleDownArrowKey : null}
        ref={buttonEl}
        icon="id_plus_icon"
        text={buttonText}
        className="c-button-icon-only"
      >
        <span className="h-hide-visually">{buttonText}</span>
        <Icon id="id_plus_icon" className="c-button-icon-only__icon" />
      </button>

      {isPopoverVisible && (
        <ClickOutside callback={clickOutside} excludeId={ID_OPEN_BTN}>
          <EscapeKeyClose callback={close}>
            <div
              aria-labelledby={ID_OPEN_BTN}
              id={ID_OVERLAY}
              ref={overlayEl}
              className="c-dropdown__overlay"
            >
              {children}
            </div>
          </EscapeKeyClose>
        </ClickOutside>
      )}
    </div>
  );
};

Dropdown.propTypes = {
  _isViewportAware: PropTypes.bool,
  buttonText: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  handleOpen: PropTypes.func,
  handleClickOutside: PropTypes.func,
  isOpenedByDownArrowKey: PropTypes.bool,
  preferredPosition: PropTypes.string
};

export default Dropdown;
