import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./SideBySide.css";

const SideBySide = ({ children, isSpaceBetween }) => {
  const classes = classNames({
    "l-side-by-side": true,
    "l-side-by-side--space-between": isSpaceBetween
  });
  return <div className={classes}>{children}</div>;
};

SideBySide.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  isSpaceBetween: PropTypes.bool
};

export default SideBySide;
