import React from "react";
import PropTypes from "prop-types";
import "./NotificationCount.css";

const NotificationCount = ({ count = 0, position = "top-right" }) => (
  <span className={`c-notification-count is-${position}`}>
    {count > 9 ? "9+" : count}
  </span>
);

NotificationCount.propTypes = {
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  position: PropTypes.string
};

export default NotificationCount;
