import React, { memo } from "react";
import PropTypes from "proptypes";
import Message from "./../Message";
import "./MessageList.css";

const MessageList = ({ data, ...props }) => {
  return (
    <ul className="c-message-list">
      {data.map(msg => (
        <Message key={msg.id} {...msg} {...props} />
      ))}
    </ul>
  );
};

MessageList.propTypes = {
  data: PropTypes.array
};

function areEqual(prevProps, nextProps) {
  /*
  return true if passing nextProps to render would return
  the same result as passing prevProps to render,
  otherwise return false
  */
  return prevProps.data.length === nextProps.data.length;
}

export default memo(MessageList, areEqual);
