import React, { Fragment, useState, useEffect } from "react";
import { Heading, SkillItem, Spacing } from "components";
import { API, getLocalStorageItems, setLocalStorageItems } from "utils";
import { Loading } from "../../../../components";

const DMSkillsTab = ({ requestRoll }) => {
  const [skills, setSkills] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await API.get("get_dictionary/T7");
        setSkills(res.data.data);
        setLocalStorageItems({
          skills: res.data.data
        });
      } catch (err) {
        console.log(err);
      }
    };
    const checkLocalStorage = () => {
      const { skills: localSkills } = getLocalStorageItems("skills");
      localSkills ? setSkills(localSkills) : getData();
      setIsLoading(false);
    };

    checkLocalStorage();
  }, []);
  return (
    <Fragment>
      <Heading text="Skills Index" spacing="large" />

      <Spacing>
        <p>Click on a skill to request a roll</p>
      </Spacing>
      {isLoading ? (
        <Loading />
      ) : (
        <ul className="c-skill-list">
          {skills &&
            skills.map((skill, i) => (
              <SkillItem key={i} name={skill.name} rollStat={requestRoll} />
            ))}
        </ul>
      )}
    </Fragment>
  );
};

export default DMSkillsTab;
