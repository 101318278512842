import React from "react";
import PropTypes from "proptypes";
import StripeCheckout from "react-stripe-checkout";
import classNames from "classnames";
import "./PriceCard.css";

import { STRIPE_PUBLISHABLE } from "./../../consts";

const CURRENCY = "GBP";

const fromEuroToCent = amount => amount * 100;

const PriceCard = ({
  benefits = [],
  cost,
  isGreen,
  isPurple,
  frequency,
  onToken,
  planId,
  title
}) => {
  const classes = classNames({
    "c-price-card": true,
    "c-price-card--green": isGreen,
    "c-price-card--purple": isPurple
  });

  return (
    <li className={classes}>
      <div className="c-price-card__header">
        <h3 className="c-price-card__title">{title}</h3>
        <div className="c-price-card__cost">
          <span className="c-price-card__dollar">&pound;</span>
          <span className="c-price-card__amount">{cost}</span>
          <span className="c-price-card__frequency">/{frequency}</span>
        </div>
      </div>
      <ul className="c-price-card__benefits">
        {benefits.map((name, i) => (
          <li key={i} className="c-price-card__benefit-item">
            {name}
          </li>
        ))}
      </ul>

      {cost > 0 && (
        <StripeCheckout
          name={title}
          description="Subscribe to RPGo to take part in  multiple games"
          amount={fromEuroToCent(cost)}
          token={onToken(
            cost,
            "Subscribe to RPGo to take part in  multiple games",
            planId
          )}
          currency={CURRENCY}
          stripeKey={STRIPE_PUBLISHABLE}
        />
      )}
    </li>
  );
};

PriceCard.propTypes = {
  benefits: PropTypes.array,
  cost: PropTypes.number,
  isGreen: PropTypes.bool,
  isPurple: PropTypes.bool,
  frequency: PropTypes.string,
  onToken: PropTypes.func,
  planId: PropTypes.string,
  title: PropTypes.string
};

export default PriceCard;
