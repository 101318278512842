import React from "react";
import PropTypes from "prop-types";

const TabList = ({ children }) => (
  <ul className="c-tabset" role="tablist">
    {children}
  </ul>
);

TabList.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default TabList;
