import React, { useState, useEffect } from "react";
import { Heading, SelectList, Spacing } from "./../../../../components";
import { API } from "./../../../../utils";

const DMEquipmentTab = () => {
  const [equipmentTypes, setEquipmentTypes] = useState([]);
  const [equipmentItems, setEquipmentItems] = useState([]);

  const getData = async () => {
    const types = await API.get("equipment");
    setEquipmentTypes(types.data.data);
  };

  // componentDidMount
  useEffect(() => {
    getData();
  }, []);

  const switchEquipmentDropdown = async e => {
    const id = e.target.value;
    const res = await API.get(`equipment/${id}`);
    setEquipmentItems(res.data.data);
  };

  return (
    <>
      <Heading text="Equipment Index" spacing="large" />
      <Spacing>
        <SelectList
          id="id_select_equipment_type"
          isLabelHidden={true}
          labelText="Types"
          name="select_equipment_type"
          defaultValue=""
          onChange={switchEquipmentDropdown}
          options={equipmentTypes}
          placeholder="Select type"
        />
      </Spacing>
      {equipmentItems.length !== 0 && (
        <Spacing>
          <SelectList
            id="id_select_equipment_item"
            isLabelHidden={true}
            labelText="Items"
            name="new_equipment"
            defaultValue=""
            options={equipmentItems}
            placeholder="Select item"
          />
        </Spacing>
      )}
    </>
  );
};

export default DMEquipmentTab;
