import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const UnAuthorisedMessage = ({ link, linkText, text, title }) => (
  <Fragment>
    <h2>{title}</h2>
    <p>{text}</p>
    <Link to={link}>{linkText}</Link>
  </Fragment>
);

UnAuthorisedMessage.propTypes = {
  link: PropTypes.string,
  linkText: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string
};

export default UnAuthorisedMessage;
