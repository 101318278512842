import React, { useState, Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import Tab from "./Tab";
import TabPane from "./TabPane";
import TabList from "./TabList";
import "./TabSet.css";

const KEYCODES = {
  leftArrow: 37,
  rightArrow: 39
};

const TabSet = ({ data, defaultHref, isResponsive }) => {
  const [hrefSelected, setHrefSelected] = useState();

  // componentDidMount
  useEffect(() => {
    setHrefSelected(
      (window.location.hash && window.location.hash.substr(1)) || defaultHref
    );
  }, [defaultHref]);

  const handleClick = e => {
    e.preventDefault();
    const hash = e.target.hash;
    // prevent default stops jumping, but also stops url updating
    window.history.pushState(null, null, hash);
    setHrefSelected(hash.substr(1));
  };

  const handleKeyPress = e => {
    if (e.keyCode === KEYCODES.leftArrow || e.keyCode === KEYCODES.rightArrow) {
      const currentIndex = Number(e.target.getAttribute("data-index"));
      let newIndex;
      let newHref;
      if (e.keyCode === KEYCODES.leftArrow) {
        newIndex = currentIndex === 0 ? data.length - 1 : currentIndex - 1;
      } else {
        //if right arrow
        newIndex = currentIndex === data.length - 1 ? 0 : currentIndex + 1;
      }
      newHref = data[newIndex].href;
      window.history.pushState(null, null, newHref);
      setHrefSelected(newHref);
    }
  };

  return (
    <>
      <TabList>
        {data.map((item, i) => (
          <Tab
            key={i}
            index={i}
            handleKeyPress={handleKeyPress}
            handleClick={handleClick}
            count={item.count}
            href={item.href}
            isSelected={hrefSelected === item.href}
            isResponsive={isResponsive}
            label={item.label}
            icon={item.icon}
          />
        ))}
      </TabList>
      {data.map((item, i) => (
        <Fragment key={i}>
          {hrefSelected === item.href && (
            <TabPane id={item.href} isSelected={hrefSelected === item.href}>
              {item.content}
            </TabPane>
          )}
        </Fragment>
      ))}
    </>
  );
};

TabSet.propTypes = {
  data: PropTypes.array,
  defaultHref: PropTypes.string,
  isResponsive: PropTypes.bool
};

export default TabSet;
