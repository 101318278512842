import React from "react";
import PropTypes from "prop-types";
import "./Badge.css";

const Badge = ({ text }) => <span className="c-badge">{text}</span>;

Badge.propTypes = {
  text: PropTypes.string
};

export default Badge;
