import React from "react";
import PropTypes from "prop-types";
import {
  Avatar,
  ButtonIconOnly,
  Heading,
  HelpTip,
  NotificationBanner,
  NotificationCount
} from "components";
import "./AdventureHeader.css";

const AdventureHeader = ({
  avatarImageUrl,
  characterName,
  handleSidebarToggle,
  helpTips = [],
  subTitle,
  title,
  unreadTotal = 0
}) => {
  return (
    <div className="l-header l-header--adventure">
      <NotificationBanner />
      <header className="c-header">
        <div className="h-positioning-context">
          <ButtonIconOnly
            iconClasses="c-icon--hover-spin"
            icon="id_d20_icon"
            isLarge
            onClick={() => handleSidebarToggle("Left")}
            text="Open channels sidebar"
          />
          {unreadTotal > 0 && <NotificationCount count={unreadTotal} />}
        </div>

        <div>
          <Heading level={1} text={title} />
          <span className="c-header__subtitle">{subTitle}</span>
        </div>
        <HelpTip
          helpTipId={1}
          text="Click here to view your character sheet. You can make rolls by
              clicking on a stat."
          shouldShow={helpTips.indexOf(1) === -1}
        >
          <Avatar
            characterName={characterName}
            imageUrl={avatarImageUrl}
            handleClick={() => handleSidebarToggle("Right")}
          />
        </HelpTip>
      </header>
    </div>
  );
};

AdventureHeader.propTypes = {
  avatarImageUrl: PropTypes.string,
  characterName: PropTypes.string,
  handleSidebarToggle: PropTypes.func,
  helpTips: PropTypes.array,
  subTitle: PropTypes.string,
  title: PropTypes.string,
  unreadTotal: PropTypes.number
};

export default AdventureHeader;
