import { getCommandString, roll } from "./index";

const makeRoll = msg => {
  const rollString = getCommandString(msg);
  const split = rollString.split("d");
  const numberOfDice = Number(split[0]) || 1;
  const numberOfSides = Number(split[1]);
  let total = 0;
  const rollLog = [];
  for (let i = 0; i < numberOfDice; i++) {
    let rollValue = roll(numberOfSides);
    rollLog.push({ sides: `d${numberOfSides}`, result: rollValue });
    total += rollValue;
  }
  console.log(rollLog);
  return `Mate, you've only gone and rolled a ${total}`;
};

export default makeRoll;
