import React from "react";
import PropTypes from "proptypes";
import { Link } from "react-router-dom";
import { Icon, Heading, Loading } from "components";
import { URLS } from "consts";

const Invitations = ({ invitations, handleDelete, isLoading }) => (
  <>
    <Heading level={1} spacing="large" text="Invitations" />
    {isLoading ? (
      <Loading />
    ) : (
      <>
        {invitations.length ? (
          invitations.map((adventure, i) => (
            <div key={i} className="c-invitation">
              <div className="c-invitation__details">
                <p className="c-invitation__title">
                  <strong>{adventure.created_by}</strong> has invited you to
                  join <strong>{adventure.name}</strong>
                </p>
                <p className="c-invitation__description h-spacing-small">
                  {adventure.description}
                </p>
                <div>
                  {adventure.tags &&
                    adventure.tags.map((tag, i) => (
                      <span className="c-tag" key={i}>
                        {tag.name}
                      </span>
                    ))}
                </div>
              </div>
              <div className="c-invitation__controls">
                <Link
                  className="c-invitation__button c-invitation__button--accept"
                  to={`${URLS.joinAdventureRoot}/${adventure.id}`}
                >
                  <Icon
                    id="id_success_icon"
                    className="c-button-icon-only__icon"
                  />
                  <span className="h-hide-visually">Accept</span>
                </Link>
                <button
                  className="c-invitation__button c-invitation__button--decline"
                  type="button"
                  onClick={handleDelete}
                  value={adventure.id}
                >
                  <Icon
                    id="id_decline_icon"
                    className="c-button-icon-only__icon"
                  />
                  <span className="h-hide-visually">Decline</span>
                </button>
              </div>
            </div>
          ))
        ) : (
          <p>You have no new invitations</p>
        )}{" "}
      </>
    )}
  </>
);

Invitations.propTypes = {
  handleDelete: PropTypes.func,
  invitations: PropTypes.array,
  isLoading: PropTypes.bool
};

export default Invitations;
