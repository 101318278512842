import React from "react";

const DetailsListItem = ({ description, title }) => {
  return (
    <>
      <dt className="c-details-list__title">{title}</dt>
      <dd className="c-details-list__description">{description}</dd>
    </>
  );
};

export default DetailsListItem;
