import React from "react";
import PropTypes from "prop-types";
import "./Tag.css";

const Tag = ({ text }) => <span className="c-tag">{text}</span>;

Tag.propTypes = {
  text: PropTypes.string
};

export default Tag;
