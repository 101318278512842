import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./Container.css";

const Container = ({
  children,
  hasCenteredContent,
  isCentered,
  isFixedWidth,
  isSmall
}) => {
  const classes = classNames({
    "l-container": true,
    "l-container--center-content": hasCenteredContent,
    "l-container--centered": isCentered,
    "l-container--small": isSmall,
    "l-container--fixed-width": isFixedWidth
  });
  return <div className={classes}>{children}</div>;
};

Container.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  hasCenteredContent: PropTypes.bool,
  isCentered: PropTypes.bool,
  isFixedWidth: PropTypes.bool,
  isSmall: PropTypes.bool
};

export default Container;
