import { isEndOfWordOrString } from "./index";

const getCommandType = message => {
  const isCommand = message.indexOf("/") === 0;
  const endOfCommand = isEndOfWordOrString(message);

  let commandType = "message";

  if (isCommand) {
    console.log("thinks its a command");
    commandType = message.substring(1, endOfCommand);
  }

  return commandType;
};

export default getCommandType;
