import React from "react";
import { Heading, HomePanel, HomePanelItem, Loading } from "components";
import { useDataApi } from "hooks";

const YourAdventures = () => {
  const { data, isLoading } = useDataApi("user_adventures", []);

  return (
    <>
      <Heading level={1} spacing="large" text="Your Adventures" />
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {data.length ? (
            <HomePanel>
              {data.map((adventure, i) => (
                <HomePanelItem key={i} data={adventure} url="adventure" />
              ))}
            </HomePanel>
          ) : (
            <p>You have no current adventures</p>
          )}
        </>
      )}
    </>
  );
};

export default YourAdventures;
