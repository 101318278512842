import getCommandString from "./getCommandString";

const sendNudgeEmails = msg => {
  const list = getCommandString(msg)
    .trim()
    .split(" ");
  console.log(list);
  return "nudged everyone";
};

export default sendNudgeEmails;
