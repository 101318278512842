import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useKindeAuth } from "kinde-auth-react";

import {
  ChangePasswordForm,
  Loading,
  Notification,
  Panel,
  Spacing,
  Button,
  Heading,
} from "components";
import { LoggedOutLayout } from "layout";
import { API, getUrlParameter } from "utils";
import { URLS } from "consts";

const ResetPassword = () => {
  // eslint-disable-next-line no-empty-pattern
  const [{}, dispatch] = useKindeAuth();

  const [values, setValues] = useState({ new_email: "", new_password: "" });
  const [isCheckingLink, setIsCheckingLink] = useState(true);
  const [isPasswordUpdated, setIsPasswordUpdated] = useState(false);

  const handleLogin = (data) => {
    dispatch({
      type: "login",
      newState: data,
    });
  };

  // componentDidMount
  useEffect(() => {
    const checkLink = async () => {
      const id = getUrlParameter("id");
      const res = await API.get(`reset_password/${id}`);
      setIsCheckingLink(false);
      setValues({ ...values, new_email: res.data.email });
    };
    checkLink();
  }, [values]);

  const onChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await API.post("reset_password", {
        new_email: values.new_email,
        new_password: values.new_password,
      });

      handleLogin(res.data);
      setIsPasswordUpdated(true);
    } catch (err) {
      console.log(err);
    }
  };

  if (isCheckingLink) {
    return <Loading />;
  }

  return (
    <LoggedOutLayout>
      {!isPasswordUpdated ? (
        <div>
          <Spacing>
            <Panel>
              {values.new_email ? (
                <ChangePasswordForm
                  handleSubmit={onSubmit}
                  title="Reset your password"
                  description="Almost done. Enter your new password, and you're
                                                good to go."
                  handleChange={onChange}
                  value={values.new_password}
                  inputLabelText="New password"
                  buttonText="Reset password"
                />
              ) : (
                <Notification
                  type="error"
                  title="Oops!"
                  text={<p>Seems that link is no longer valid.</p>}
                />
              )}
            </Panel>
          </Spacing>
          <p className="c-login-text">
            <Link to={URLS.login}>Return to login</Link>
          </p>{" "}
        </div>
      ) : (
        <>
          <Spacing>
            <Heading text="Nice one!" />
          </Spacing>
          <Spacing>
            <Notification
              type="success"
              title="Success!"
              text={
                <p>
                  We've updated the password for{" "}
                  <strong>{values.new_email}</strong>.
                </p>
              }
            />
          </Spacing>
          <Button href="/login" text="Return to Login" />
        </>
      )}
    </LoggedOutLayout>
  );
};

export default ResetPassword;
