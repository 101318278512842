import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { useKindeAuth } from "kinde-auth-react";
import { API } from "utils";
import { loginErrorMessages, URLS } from "consts";
import {
  Button,
  ButtonGroup,
  Container,
  CopyText,
  Form,
  Header,
  Heading,
  InputField,
  Panel,
  Spacing,
} from "components";

const Welcome = () => {
  const { user } = useKindeAuth();

  const [newUsername, setNewUsername] = useState("");
  const [errorText, setErrorText] = useState("");

  const onSubmit = async (e) => {
    e.preventDefault();
    // try {
    //   await API.put("users", {
    //     new_username: newUsername,
    //   });
    //   dispatch({
    //     type: "updateState",
    //     newState: { username: newUsername },
    //   });
    // } catch (err) {
    //   if (err.response) {
    //     setErrorText(err.response.data.fields);
    //   }
    // }
    console.log("username");
  };
  if (user.first_name) {
    return <Redirect to={URLS.home} />;
  }

  return (
    <>
      <Header title="Welcome" />

      <Container isFixedWidth isCentered>
        <Panel>
          <Heading text="Welcome to RPGo!" level={1} spacing="large" />
          <Spacing>
            <CopyText>
              <p>
                You'll need a unique username, before you go through to choose
                or create your first adventure!
              </p>
              <p>
                This will be how other users see you in global areas of the app.
              </p>
            </CopyText>
          </Spacing>
          <Form onSubmit={onSubmit}>
            <Spacing>
              <InputField
                name="new_username"
                onChange={(e) => setNewUsername(e.target.value)}
                value={newUsername}
                labelText="Username"
                autoComplete="off"
                error={
                  loginErrorMessages[
                    errorText.new_username && errorText.new_username.type
                  ]
                }
              />
            </Spacing>
            <ButtonGroup isAlignedRight>
              <Button text="Save" />
            </ButtonGroup>
          </Form>
        </Panel>
      </Container>
    </>
  );
};

export default Welcome;
