import React from "react";
import {
  Avatar,
  ButtonIconOnly,
  Container,
  Heading,
  TabSet,
  SideBarHeader,
  SideBar,
  Spacing
} from "./../../../components";

import CharacterDetailsTab from "./CharacterDetailsTab";
import CharacterEquipmentTab from "./CharacterEquipmentTab";
import CharacterSkillsTab from "./CharacterSkillsTab";
import CharacterSpellsTab from "./CharacterSpellsTab";
import CharacterNotesTab from "./CharacterNotesTab";
import "./AdventureRightSidebar.css";

const RightSideBar = ({
  amendHitPoints,
  character,
  currentHealth,
  equipment,
  hideSidebar,
  isVisible,
  skills,
  spells,
  stats,
  rollStat,
  notes,
  updateNotes,
  updateWeapon,
  updateXP,
  weaponPrimaryHand,
  weaponOffHand,
  xp
}) => (
  <SideBar isVisible={isVisible} side="right">
    <SideBarHeader>
      <div className="h-align-right">
        <ButtonIconOnly
          icon="id_close_icon"
          onClick={hideSidebar}
          text="Close sidebar"
        />
      </div>
      <Container hasCenteredContent>
        <Spacing>
          <Avatar
            characterName={character.name}
            imageUrl={character.avatar}
            isLarge={true}
          />
        </Spacing>
        <Heading text={character.name} isColored={true} />

        <p className="c-profile-description">
          Level 1 {character.class_name}, {character.race}
        </p>
      </Container>
    </SideBarHeader>
    <TabSet
      data={[
        {
          href: "character-details",
          label: "Details",
          icon: "id_profile_icon",
          shouldDisplay: character.name !== "DM",
          content: (
            <CharacterDetailsTab
              amendHitPoints={amendHitPoints}
              character={character}
              currentHealth={currentHealth}
              equipment={equipment}
              rollStat={rollStat}
              stats={stats}
              weaponPrimaryHand={weaponPrimaryHand}
              weaponOffHand={weaponOffHand}
            />
          )
        },
        {
          href: "character-skills",
          label: "Skills",
          icon: "id_skills_icon",
          content: (
            <CharacterSkillsTab
              character={character}
              skills={skills}
              rollStat={rollStat}
            />
          )
        },
        {
          href: "character-equipment",
          label: "Equipment",
          icon: "id_equipment_icon",
          content: (
            <CharacterEquipmentTab
              character={character}
              equipment={equipment}
              updateWeapon={updateWeapon}
            />
          )
        },
        {
          href: "character-notes",
          label: "Notes",
          icon: "id_notes_icon",
          content: (
            <CharacterNotesTab
              notes={notes}
              updateNotes={updateNotes}
              xp={xp}
              updateXP={updateXP}
            />
          )
        },
        {
          href: "character-spells",
          label: "Spells",
          icon: "id_magic_icon",
          content: (
            <CharacterSpellsTab
              spells={spells}
              character={character}
              rollStat={rollStat}
            />
          )
        }
      ]}
      defaultHref="character-details"
    />
  </SideBar>
);

export default RightSideBar;
