import React, { Fragment } from "react";
import { InputField, Spacing } from "./../../../../components";

const CharacterNotesTab = ({ updateXP, xp, notes, updateNotes }) => (
  <Fragment>
    <Spacing>
      <InputField name="new_xp" value={xp} labelText="XP" onChange={updateXP} />
    </Spacing>
    <Spacing>
      <InputField
        isMultiline
        name="new_notes"
        value={notes}
        labelText="Notes"
        onChange={updateNotes}
      />
    </Spacing>
  </Fragment>
);

export default CharacterNotesTab;
