import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ForgotPassword, ResetPassword, AppRoutes, Landing } from "pages";
import { URLS } from "consts";

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route path={URLS.forgotPassword} component={ForgotPassword} />
      <Route path={URLS.resetPassword} component={ResetPassword} />
      <Route path={URLS.app} component={AppRoutes} />
      <Route path="/" component={Landing} />
    </Switch>
  </BrowserRouter>
);

export default Routes;
