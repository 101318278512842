const loginErrorMessages = Object.freeze({
  "100": "There is no account for this email",
  "200": "There is already an account for this email",
  "300": "Registration incomplete for this email, please register.",
  "600": "Email and password don't match",
  "700": "This username has already been taken",
  "800": "This reset password link has expired",
  "900": "This reset password link is invalid",
  "1000": "This email validation link is invalid",
  "2000": "An unknown error occurred"
});

export default loginErrorMessages;
