import React, { Fragment } from "react";
import { InputField, Spacing } from "./../../../../components";

const DMNotesTab = ({ notes, updateNotes }) => (
  <Fragment>
    <Spacing>
      <InputField
        isMultiline
        name="new_notes"
        value={notes}
        labelText="Notes"
        onChange={updateNotes}
      />
    </Spacing>
  </Fragment>
);

export default DMNotesTab;
