import React from "react";
import PropTypes from "prop-types";

const NewLineToParagraph = ({ content }) =>
  content.split("\n").map((item, i, arr) => (
    <span key={i}>
      {item}
      {arr.length - 1 === i ? null : <br />}
    </span>
  ));

NewLineToParagraph.propTypes = {
  content: PropTypes.string
};

export default NewLineToParagraph;
