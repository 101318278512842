/**
 * Human readable format for primary keyboard controls.
 * @type {Object}
 */
const KEY_CODES = Object.freeze({
  a: 65,
  backspace: 8,
  downArrow: 40,
  end: 35,
  enter: 13,
  escape: 27,
  home: 36,
  leftArrow: 37,
  rightArrow: 39,
  space: 32,
  tab: 9,
  upArrow: 38
});

export default KEY_CODES;
