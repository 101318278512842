import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  ErrorMessage,
  Fieldset,
  Form,
  Heading,
  InputField,
  Notification,
  Panel,
  Spacing
} from "components";
import { LoggedOutLayout } from "layout";
import { API } from "utils";
import { loginErrorMessages, URLS } from "consts";

const ForgotPassword = () => {
  const [errorText, setErrorText] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [isEmailSent, setIsEmailSent] = useState(false);

  const onSubmit = async e => {
    e.preventDefault();
    try {
      const res = await API.post("send_reset_email", { new_email: newEmail });
      setIsEmailSent(res.status === 200);
    } catch (err) {
      setErrorText(loginErrorMessages[err.response.data.type]);
    }
  };

  return (
    <LoggedOutLayout>
      {!isEmailSent ? (
        <div>
          <Spacing>
            <Panel>
              <Form onSubmit={onSubmit}>
                <Spacing>
                  <Fieldset title="Forgot your password?">
                    <Spacing>
                      <p>
                        Don't worry! We'll email you instructions to reset it
                        and then you can get back to adventuring!
                      </p>
                    </Spacing>
                    <InputField
                      name="new_email"
                      onChange={e => setNewEmail(e.target.value)}
                      value={newEmail}
                      labelText="Email"
                      type="email"
                    />
                  </Fieldset>
                </Spacing>

                <Button isFullWidth text="Send reset password email" />
              </Form>
              <ErrorMessage text={errorText} />
            </Panel>
          </Spacing>
          <p className="c-login-text">
            <Link to={URLS.login}>Return to login</Link>
          </p>
        </div>
      ) : (
        <>
          <Spacing>
            <Heading text="Help is on the way!" />
          </Spacing>
          <Spacing>
            <Notification
              type="success"
              title="Success!"
              text={
                <p>
                  We've sent an email to <strong>{newEmail}</strong> with
                  password reset instructions
                </p>
              }
            />
          </Spacing>
          <Spacing>
            <p>
              If the email doesn't show up soon, check your spam folder. We sent
              it from <strong>confirm@rpgoapp.com</strong>.
            </p>
          </Spacing>
          <Button href="/login" text="Return to Login" />
        </>
      )}
    </LoggedOutLayout>
  );
};

export default ForgotPassword;
