import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { ButtonGroup, ButtonIconOnly, ButtonLink } from "components";
import "./NotificationBanner.css";

const NotificationBanner = () => {
  const [
    isNotificationBannerVisible,
    setIsNotificationBannerVisible
  ] = useState(false);

  const [isDoubleCheck, setIsDoubleCheck] = useState(false);

  const setBannerDisplay = shouldShow => {
    const method = shouldShow ? "add" : "remove";
    setIsNotificationBannerVisible(shouldShow);
    document.body.classList[method]("is-notification-visible");
  };

  useEffect(() => {
    if ("Notification" in window && Notification.permission === "default") {
      setBannerDisplay(true);
    }
  }, []);

  const checkPermission = () => {
    Notification.requestPermission(permission => {
      if (permission !== "default") {
        setBannerDisplay(false);
      }
    });
  };

  const classes = classNames({
    "c-notification-banner": true,
    "h-invisible": !isNotificationBannerVisible
  });

  return (
    <div
      className={classes}
      aria-hidden={isNotificationBannerVisible ? null : true}
    >
      {!isDoubleCheck ? (
        <>
          <span className="c-notification-banner__inner">
            <strong className="c-notification-banner__title">RPGo</strong> needs
            your permission to{" "}
            <ButtonLink onClick={checkPermission} text="enable notifications" />
            .
          </span>
          <ButtonIconOnly
            icon="id_close_icon"
            text="Close"
            onClick={() => setIsDoubleCheck(true)}
          />
        </>
      ) : (
        <div>
          <p>
            We strongly recommend you enable notifications if you are going to
            be using RPGo on this device.
          </p>
          <ButtonGroup>
            <ButtonLink
              onClick={checkPermission}
              isBold
              text="Enable notifications"
            />
            <ButtonLink
              onClick={() => setBannerDisplay(false)}
              text="Ask me next time"
            />
            <ButtonLink
              onClick={checkPermission}
              text="Never ask again on this computer"
            />
          </ButtonGroup>
        </div>
      )}
    </div>
  );
};

export default NotificationBanner;
