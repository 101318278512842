import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import PropTypes from "proptypes";
import {
  Avatar,
  Button,
  ButtonGroup,
  Container,
  CopyText,
  Fieldset,
  Form,
  Header,
  InputField,
  Loading,
  Panel,
  SelectList,
  Spacing,
  SubscriptionList
} from "components";
import { API, getLocalStorageItems, setLocalStorageItems } from "utils";
import { URLS } from "consts";

const JoinAdventure = ({ match }) => {
  const [values, setValues] = useState({
    new_name: "",
    new_race: "",
    new_class: "",
    new_gender: "",
    new_alignment: ""
  });
  const [races, setRaces] = useState([]);
  const [classes, setClasses] = useState([]);
  const [genders, setGenders] = useState([]);
  const [alignments, setAlignments] = useState([]);
  const [isPremium, setIsPremium] = useState(false);
  const [isAlreadyInGame, setIsAlreadyInGame] = useState(false);
  const [hasReachedMax, setHasReachedMax] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [channelId, setChannelId] = useState();
  const [gameCount, setGameCount] = useState(0);
  const [hasJoinedGame, setHasJoinedGame] = useState(false);

  const setDictionariesState = ({ alignments, races, classes, genders }) => {
    setAlignments(alignments);
    setRaces(races);
    setClasses(classes);
    setGenders(genders);
  };

  // componentDidMount
  useEffect(() => {
    const getData = async () => {
      const { adventureId } = match.params;
      const eligiblility = await API.get(`check_eligibility/${adventureId}`);

      const premium = await API.get(`check_premium`);
      setChannelId(eligiblility.data.data.channel_id);
      setIsAlreadyInGame(eligiblility.data.data.is_in_game !== "0");
      setHasReachedMax(
        Number(eligiblility.data.data.pc_count) >=
          Number(eligiblility.data.data.max_pcs)
      );

      setGameCount(premium.data.data.character_count);
      setIsPremium(premium.data.data.is_premium);
      setIsLoading(false);
    };
    const getDictonariesData = async () => {
      try {
        const dictionaries = await API.post("get_dictionary", {
          tables: ["T3", "T4", "T5", "T6"]
        });
        setDictionariesState(dictionaries.data.data);
        setLocalStorageItems({
          ...dictionaries.data.data
        });
      } catch (err) {
        console.log(err);
      }
    };
    const checkLocalStorage = () => {
      const { alignments } = getLocalStorageItems("alignments");
      alignments
        ? setDictionariesState(
            getLocalStorageItems("alignments", "races", "classes", "genders")
          )
        : getDictonariesData();
    };
    getData();
    checkLocalStorage();
  }, [match.params]);

  const onChange = e => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const choosePortrait = (raceId, genderId) => {
    const race = races.find(race => Number(raceId) === Number(race.id));
    const gender = Number(genderId) === 1 ? "male" : "female";
    return race && gender
      ? `portraits/${race.name.toLowerCase()}_${gender}.png`
      : null;
  };

  const onSubmit = async e => {
    e.preventDefault();
    setIsSubmitting(true);
    const { adventureId } = match.params;
    const res = await API.post(
      "join_adventure",
      Object.assign(values, {
        adventureId,
        new_avatar: choosePortrait(values.new_race, values.new_gender)
      })
    );
    setChannelId(res.data.channelId);
    setHasJoinedGame(true);
  };

  if (hasJoinedGame || isAlreadyInGame) {
    return (
      <Redirect
        to={`${URLS.adventureRoot}/${match.params.adventureId}/${channelId}`}
      />
    );
  }
  if (isLoading) {
    return <Loading />;
  }

  if (hasReachedMax) {
    return (
      <div>
        <p>Ah man, looks like this game already maxed out on players.</p>
        <p>
          <Link to={URLS.home}>Find another game</Link>
        </p>
      </div>
    );
  }

  return (
    <>
      <Header title="Join Adventure" />
      {gameCount > 0 && !isPremium ? (
        <Container>
          <Spacing>
            <CopyText>
              <p>
                We're glad you're keen to play more games, but our Free members
                are only allowed to have a character in one game at a time
              </p>
              <p>
                Sign up to RPGo premium to play as many games as you'd like!
              </p>
            </CopyText>
          </Spacing>
          <SubscriptionList />
        </Container>
      ) : (
        <Container isCentered>
          <Spacing>
            <Panel>
              <CopyText>
                <p>
                  Add some basic character details and jump into the action!
                </p>
              </CopyText>
            </Panel>
          </Spacing>
          <Panel>
            <Form onSubmit={onSubmit} autoComplete="off">
              <Fieldset title="Character Details">
                {values.new_gender && values.new_race && (
                  <Spacing>
                    <Avatar
                      characterName={values.new_name}
                      imageUrl={choosePortrait(
                        values.new_race,
                        values.new_gender
                      )}
                      isLarge={true}
                    />
                  </Spacing>
                )}
                <InputField
                  name="new_name"
                  onChange={onChange}
                  value={values.new_name}
                  labelText="Character name"
                  required
                />
              </Fieldset>
              <Spacing />
              <Spacing>
                <SelectList
                  labelText="Race"
                  name="new_race"
                  value={values.new_race}
                  onChange={onChange}
                  options={races}
                  placeholder="Select race"
                  required
                />
              </Spacing>
              <Spacing>
                <SelectList
                  labelText="Class"
                  name="new_class"
                  value={values.new_class}
                  onChange={onChange}
                  options={classes}
                  placeholder="Select class"
                  required
                />
              </Spacing>
              <Spacing>
                <SelectList
                  labelText="Gender"
                  name="new_gender"
                  value={values.new_gender}
                  onChange={onChange}
                  options={genders}
                  placeholder="Select gender"
                  required
                />
              </Spacing>
              <Spacing>
                <SelectList
                  labelText="Alignment"
                  name="new_alignment"
                  value={values.new_alignment}
                  onChange={onChange}
                  options={alignments}
                  placeholder="Select alignment"
                  required
                />
              </Spacing>

              <ButtonGroup isSpaceBetween>
                <Link to={URLS.home}>Cancel</Link>
                <Button text="Join Adventure" isLoading={isSubmitting} />
              </ButtonGroup>
            </Form>
          </Panel>
        </Container>
      )}
    </>
  );
};

JoinAdventure.propTypes = {
  match: PropTypes.object
};

export default JoinAdventure;
