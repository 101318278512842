import React, { useState, useEffect } from "react";
import {
  Avatar,
  Button,
  ButtonGroup,
  ButtonIconOnly,
  Dialog,
  Fieldset,
  Form,
  Heading,
  InvitePlayer,
  ListHeader,
  PlayersList,
  Spacing
} from "./../../../../components";
import { API } from "./../../../../utils";

const DMCharacterList = ({ adventureId, characters, maxPCs }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newUsersToInvite, setNewUsersToInvite] = useState([]);
  const [invited, setInvited] = useState([]);

  // componentDidMount
  useEffect(() => {
    const getData = async () => {
      const res = await API.get(`adventure_invitations/${adventureId}`);
      setInvited(res.data.data || []);
    };
    getData();
  }, [adventureId]);

  const openModal = () => setIsModalOpen(true);

  const closeModal = () => {
    setIsModalOpen(true);
    setNewUsersToInvite([]);
  };

  const updateUsersToInvite = value => setNewUsersToInvite(value);

  const handleSubmit = e => {
    e.preventDefault();
    const newlyInvited = newUsersToInvite;
    API.post("adventure_invitations", {
      new_users: newlyInvited.map(i => i.email),
      adventureId
    });
    setInvited(invited.concat(newlyInvited));
    setNewUsersToInvite([]);
    setIsModalOpen(false);
  };

  const removeFromPlayers = e => {
    API.put(`decline_invite/${adventureId}`, { email: e.target.value });
    setInvited(invited.filter(p => p.email !== e.target.value));
  };

  const totalPlayers = invited.length + characters.length;

  return (
    <>
      <ListHeader>
        <Heading text="PC Index" />
        {totalPlayers < maxPCs && (
          <ButtonIconOnly
            onClick={openModal}
            text="Add PC"
            icon="id_plus_icon"
          />
        )}
      </ListHeader>

      <ul>
        {characters.map((char, i) => (
          <li key={i} className="l-side-by-side l-side-by-side--large">
            <div className="h-positioning-context h-spacing-2x-large">
              <Avatar characterName={char.name} imageUrl={char.avatar} />
              <p className="c-health-badge">{char.current_health}</p>
            </div>
            <div>
              <h4>
                {char.name} ({char.player_name})
              </h4>
              <p>
                {char.race} {char.class_name}, Level {char.level}
              </p>
            </div>
          </li>
        ))}
      </ul>
      {!!invited.length && <h3>Invited</h3>}
      <PlayersList data={invited} removeItem={removeFromPlayers} />

      <Dialog
        hasCloseButton={false}
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="yep"
        title="Add Characters"
      >
        <Form onSubmit={handleSubmit}>
          <Fieldset title="">
            <Spacing>
              <InvitePlayer
                adventureId={adventureId}
                playerCount={maxPCs - totalPlayers}
                handleChange={updateUsersToInvite}
              />
            </Spacing>
            <ButtonGroup>
              <Button
                isSecondary
                onClick={closeModal}
                text="Cancel"
                type="button"
              />
              <Button text="Invite" />
            </ButtonGroup>
          </Fieldset>
        </Form>
      </Dialog>
    </>
  );
};

export default DMCharacterList;
