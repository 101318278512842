import React from "react";
import { SkillItem } from "./../../../../components";

const CharacterSkillsTab = ({ rollStat, skills }) => {
  return (
    <ul className="c-skill-list">
      {skills &&
        skills.map((skill, i) => (
          <SkillItem
            key={i}
            name={skill.name}
            rollStat={rollStat}
            value={skill.value}
          />
        ))}
    </ul>
  );
};

export default CharacterSkillsTab;
