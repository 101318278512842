import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Label, ErrorMessage } from "components";
import "./InputField.css";

const InputField = ({
  error,
  isDirty,
  name,
  type,
  value = "",
  labelText,
  isMultiline,
  isLabelHidden,
  isSlim,
  onChange,
  ...props
}) => {
  const classes = classNames({
    "c-input-field__input": true,
    "c-input-field__input--text": !isMultiline || isSlim,
    "c-input-field__input--textarea": isMultiline && !isSlim,
    "is-dirty": isDirty,
    "is-invalid": error
  });

  const Tag = isMultiline ? "textarea" : "input";

  return (
    <div className="c-input-field">
      <Label forId={`id_${name}`} isHidden={isLabelHidden} text={labelText} />
      <Tag
        {...props}
        aria-describedby={error ? `id_${name}_error` : null}
        aria-invalid={error ? true : null}
        id={`id_${name}`}
        className={classes}
        type={isMultiline ? null : type || "text"}
        name={name}
        onChange={onChange}
        value={value}
      />
      {error && <ErrorMessage id={`id_${name}_error`} text={error} />}
    </div>
  );
};

InputField.propTypes = {
  error: PropTypes.string,
  isMultiline: PropTypes.bool,
  isLabelHidden: PropTypes.bool,
  isSlim: PropTypes.bool,
  labelText: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default InputField;
