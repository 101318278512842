import React from "react";
import PropTypes from "prop-types";
import { Label } from "./../../components";
import "./CheckBox.css";

const CheckBox = ({
  checkBoxClassName,
  checkBoxLabelClassName,
  idx,
  isLabelHidden,
  labelText,
  name,
  value = "",
  ...props
}) => (
  <p className="c-checkbox-button">
    <input
      {...props}
      id={`id_${name}_${idx}`}
      className={
        checkBoxClassName || "c-checkbox-button__input h-hide-visually"
      }
      type="checkbox"
      name={name}
      value={value}
    />
    <Label
      forId={`id_${name}_${idx}`}
      isHidden={isLabelHidden}
      text={labelText}
      className={checkBoxLabelClassName || "c-checkbox-button__label"}
    />
  </p>
);

CheckBox.propTypes = {
  checkBoxClassName: PropTypes.string,
  checkBoxLabelClassName: PropTypes.string,
  idx: PropTypes.number,
  isLabelHidden: PropTypes.bool,
  labelText: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default CheckBox;
