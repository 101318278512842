/**
 * Generates a function map with the ability to check each viewport direction.
 * @param {Node} target - The item to be place onto the viewport.
 * @param {Node} trigger - The item it is to be placed relative to.
 * @param {Node} viewport - The document window.
 * @param {Object} offsets - Gutter between viewpoint and target element, margin between trigger
 * and target.
 * @return {Object} - A function map.
 */
const generateViewPortMap = (target, trigger, viewport, offsets = {}) => {
  const { marginOffset = 0, viewPortGutter = 2 } = offsets;
  const {
    height: targetHeight,
    width: targetWidth
  } = target.getBoundingClientRect();
  const triggerDx = trigger.getBoundingClientRect();
  const { innerHeight: viewportHeight, innerWidth: viewportWidth } = viewport;
  const totalOffset = marginOffset + viewPortGutter;
  const triggerCentre = () => triggerDx.left + triggerDx.width / 2; // X axis
  const triggerMiddle = () => triggerDx.top + triggerDx.height / 2; // Y axis

  const bottomX = () =>
    triggerDx.bottom + targetHeight + totalOffset <= viewportHeight;
  const leftX = () => triggerDx.left - (targetWidth + totalOffset) >= 0;
  const topX = () => triggerDx.top - (targetHeight + totalOffset) >= 0;
  const rightX = () =>
    triggerDx.right + targetWidth + totalOffset <= viewportWidth;

  const xLeft = () =>
    triggerDx.left + viewPortGutter + targetWidth <= viewportWidth;
  const xRight = () => triggerDx.right - viewPortGutter - targetWidth >= 0;

  const xMiddle = () =>
    triggerMiddle() + targetHeight / 2 >= 0 &&
    triggerMiddle() + targetHeight / 2 <= viewportHeight;
  const xCenter = () =>
    triggerCentre() - targetWidth / 2 >= 0 &&
    triggerCentre() + targetWidth / 2 <= viewportWidth;

  return {
    "bottom-center": () => bottomX() && xCenter(),
    "bottom-left": () => bottomX() && xLeft(),
    "bottom-right": () => bottomX() && xRight(),
    "left-middle": () => leftX() && xMiddle(),
    "right-middle": () => rightX() && xMiddle(),
    "top-center": () => topX() && xCenter(),
    "top-left": () => topX() && xLeft(),
    "top-right": () => topX() && xRight()
  };
};

export default generateViewPortMap;
