import React from "react";
import PropTypes from "prop-types";
import { OverlayButton } from "./../../components";
import "./Suggestion.css";

const Suggestion = ({
  description,
  example,
  handleClick,
  params,
  secondaryValue,
  title,
  value
}) => (
  <li className="c-suggestion">
    <p>
      <strong className="c-suggestion__title">{title}</strong>{" "}
      {params && <span>{params}</span>}
    </p>
    <p className="c-suggestion__details">
      <span className="c-suggestion__description">{description}</span>
      {example && <span className="c-suggestion__example">{example}</span>}
    </p>
    {handleClick && (
      <OverlayButton
        onClick={handleClick}
        text="Select item"
        value={value}
        data-secondary-value={secondaryValue}
        type="button"
      />
    )}
  </li>
);

Suggestion.propTypes = {
  description: PropTypes.string,
  example: PropTypes.string,
  handleClick: PropTypes.func,
  params: PropTypes.string,
  secondaryValue: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.string
};

export default Suggestion;
