import { useState, useEffect } from "react";
import { API } from "./../utils";
import { useKindeAuth } from "kinde-auth-react";

const useDataApi = (initialUrl, initialData) => {
  const { getToken } = useKindeAuth();
  const [data, setData] = useState(initialData);
  const [url, setUrl] = useState(initialUrl);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsError(false);
      setIsLoading(true);

      try {
        const accessToken = await getToken();
        const result = await API.get(url, {
          headers: {
            // Add the Authorization header to the existing headers
            Authorization: `Bearer ${accessToken}`,
          },
        });
        setData(result.data.data);
      } catch (error) {
        setIsError(true);
      }

      setIsLoading(false);
    };
    fetchData();
  }, [getToken, url]);

  const doGet = (event, url) => {
    setUrl(url);
    event.preventDefault();
  };

  return { data, isLoading, isError, doGet };
};

export default useDataApi;
