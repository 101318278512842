import React, { useState, useEffect } from "react";
import { requestRoll, rollStat, debounce, API } from "utils";
import Channel from "./Channel";
import { Loading, UnAuthorisedMessage } from "components";
import AdventureHeader from "./AdventureHeader";
import AdventureLeftSideBar from "./AdventureLeftSideBar";
import AdventureRightSideBar from "./AdventureRightSideBar";
import DMRightSideBar from "./DMRightSideBar";

const Adventure = ({ match }) => {
  const [abilityRollMessage, setAbilityRollMessage] = useState("");
  const [adventure, setAdventure] = useState({});

  const [character, setCharacter] = useState({});
  const [channels, setChannels] = useState([]);
  const [currentHealth, setCurrentHealth] = useState(0);
  const [equipment, setEquipment] = useState([]);
  const [errorCode, setErrorCode] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isLeftSideBarShown, setIsLeftSideBarShown] = useState(false);
  const [isRightSideBarShown, setIsRightSideBarShown] = useState(false);
  const [newNotes, setNewNotes] = useState();
  const [helpTips, setHelpTips] = useState([]);
  const [newXP, setNewXP] = useState();
  const [skills, setSkills] = useState([]);
  const [stats, setStats] = useState([]);
  const [spells, setSpells] = useState([]);
  const [weaponPrimaryHand, setWeaponPrimaryHand] = useState([]);
  const [weaponOffHand, setWeaponOffHand] = useState([]);
  const [requestRollMessage, setRequestRollMessage] = useState("");

  useEffect(() => {
    const getData = async () => {
      const adventureId = match.params.adventureId;
      try {
        // PC Specific except "notes" and "channels" - TO DO: refactor request
        const adventureRes = await API.get(`adventures/${adventureId}`);
        const charRes = await API.get(`characters/${adventureId}`);

        // Shared
        setAdventure(adventureRes.data.adventure);
        setHelpTips(adventureRes.data.helpTips.tips);
        setChannels(charRes.data.characterChannels);
        setCharacter(charRes.data.character);
        setNewNotes(charRes.data.character.notes || "");

        // Character only
        setCurrentHealth(charRes.data.character.current_health);
        setEquipment(charRes.data.characterEquipment);
        setNewXP(charRes.data.character.xp || 0);
        setStats(charRes.data.characterAbilities);
        setSkills(charRes.data.characterSkills);
        setSpells(charRes.data.characterSpells);
        setWeaponPrimaryHand(
          charRes.data.characterEquipment.find(
            item => item.character_body_map_id === 1
          )
        );
        setWeaponOffHand(
          charRes.data.characterEquipment.find(
            item => item.character_body_map_id === 2
          )
        );

        setIsLoading(false);
      } catch (err) {
        console.log(err);
        setErrorCode(err.data.type);
      }
    };
    getData();
  }, [match.params.adventureId]);

  const toggleSidebar = side => {
    setIsLeftSideBarShown(side === "Left");
    setIsRightSideBarShown(side === "Right");
  };

  const saveValue = (field, url, debounceLength = 0) => {
    debounce(() => {
      API.post(url, {
        id: character.id,
        [field.name]: field.value
      });
    }, debounceLength)();
  };

  const updateNotes = e => {
    setNewNotes(e.target.value);
    saveValue(e.target, "update_notes", 500);
  };

  // DM
  const rollAbility = (name, base) => {
    setAbilityRollMessage(rollStat(name, base));
    toggleSidebar("right");
  };

  const clearRollAbility = () => {
    setAbilityRollMessage("");
  };

  // Char
  const requestForRoll = name => {
    setRequestRollMessage(requestRoll(name));
    toggleSidebar("right");
  };

  const clearRequestForRoll = () => {
    setRequestRollMessage("");
  };

  const updateWeapon = weapon => {
    weapon.character_body_map_id === 1
      ? setWeaponPrimaryHand(weapon)
      : setWeaponOffHand(weapon);
  };

  const updateXP = e => {
    setNewXP(e.target.value);
    saveValue(e.target, "update_xp", 500);
  };

  const amendHitPoints = amount => {
    const hp = Number(currentHealth) + Number(amount);
    setCurrentHealth(hp);
    saveValue({ name: "currentHealth", value: hp }, "update_hitpoints");
  };

  if (errorCode === "100") {
    return (
      <UnAuthorisedMessage
        link="/app/home"
        linkText="Go back on the horse you rode in on"
        text="This Adventure may be private. If someone gave you this link, they may need
                        to invite you to join the adventure first."
        title="Adventure not found."
      />
    );
  }

  if (isLoading) {
    return <Loading />;
  }

  const { channelId } = match.params;

  const channelArr = channels.filter(ch => Number(ch.id) === Number(channelId));

  const channelName = channelArr[0] && channelArr[0].name;

  const getTotalUnread = () =>
    channels.reduce((acc, ch) => Number(ch.message_count) + acc, 0);

  if (!channelName) {
    return (
      <UnAuthorisedMessage
        link="/app/home"
        linkText="Go back on the horse you rode in on"
        text="This channel may be private. If someone gave you this link, they may need to
                        invite you to join the channel first."
        title="Channel not found."
      />
    );
  }

  return (
    <div className="l-page">
      <AdventureHeader
        avatarImageUrl={character.avatar || "portraits/dm.jpg"}
        characterName={character.name}
        handleSidebarToggle={toggleSidebar}
        subTitle={channelName}
        title={adventure.name}
        unreadTotal={getTotalUnread()}
        helpTips={helpTips}
      />

      <AdventureLeftSideBar
        adventureId={adventure.id}
        channels={channels}
        hideSidebar={() => setIsLeftSideBarShown(false)}
        isVisible={isLeftSideBarShown}
      />

      <Channel
        abilityRollMessage={abilityRollMessage}
        adventureId={adventure.id}
        channelId={channelId}
        character={character}
        clearRequestForRoll={clearRequestForRoll}
        clearRollAbility={clearRollAbility}
        key={channelId}
        requestRollMessage={requestRollMessage}
      />

      {character.name !== "DM" ? (
        <AdventureRightSideBar
          adventureId={adventure.id}
          amendHitPoints={amendHitPoints}
          character={character}
          currentHealth={currentHealth}
          equipment={equipment}
          hideSidebar={() => setIsRightSideBarShown(false)}
          isVisible={isRightSideBarShown}
          notes={newNotes}
          rollStat={rollAbility}
          skills={skills}
          spells={spells}
          stats={stats}
          updateNotes={updateNotes}
          updateWeapon={updateWeapon}
          updateXP={updateXP}
          weaponPrimaryHand={weaponPrimaryHand}
          weaponOffHand={weaponOffHand}
          xp={newXP}
        />
      ) : (
        <DMRightSideBar
          adventureId={adventure.id}
          adventureMaxPCs={adventure.max_pcs}
          character={character}
          hideSidebar={() => setIsRightSideBarShown(false)}
          isVisible={isRightSideBarShown}
          notes={newNotes}
          requestRoll={requestForRoll}
          updateNotes={updateNotes}
        />
      )}
    </div>
  );
};

export default Adventure;
