import React, { useEffect } from "react";
import PropTypes from "proptypes";

/**
 * Adds a click event (`mousedown` specifically) and a touch event (`touchstart` specifically) to
 * the document but will ignore the component it wraps.
 *
 * This is typically used for closing a certain part of a component after it's been opened, for
 * example, a drop down menu once opened can be closed by clicking anywhere in the document other
 * than the drop down menu.
 */
const ClickOutside = ({ children, callback, excludeId }) => {
  // eslint-disable-next-line class-methods-use-this
  const preventClose = e => {
    e.stopPropagation();
  };

  // componentDidMount
  useEffect(() => {
    const bindEvents = () => {
      window.addEventListener("mousedown", invokeCallback, false);
      window.addEventListener("touchstart", invokeCallback, false);
    };

    const unbindEvents = () => {
      window.removeEventListener("mousedown", invokeCallback);
      window.removeEventListener("touchstart", invokeCallback);
    };

    const invokeCallback = e => {
      if (e.target && e.target.id !== excludeId) {
        callback();
      }
    };
    bindEvents();
    return unbindEvents;
  }, [callback, excludeId]);

  return (
    <div
      className="c-click-outside"
      onMouseDown={preventClose}
      onTouchStart={preventClose}
      role="presentation"
    >
      {children}
    </div>
  );
};

ClickOutside.propTypes = {
  /**
   * A callback function that is triggered from a child component.
   */
  callback: PropTypes.func.isRequired,
  /**
   * Child elements.
   */
  children: PropTypes.node.isRequired,
  /**
   * Exclude an element by passing in its id.
   */
  excludeId: PropTypes.string
};

ClickOutside.defaultProps = {
  callback: () => {}
};

export default ClickOutside;
