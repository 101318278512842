import React from "react";
import { useKindeAuth } from "kinde-auth-react";
import { PriceCard } from "./../../components";
import { API } from "./../../utils";
import "./SubscriptionList.css";

const CURRENCY = "GBP";

const fromEuroToCent = (amount) => amount * 100;

const plans = [
  {
    title: "Basic plan",
    cost: 0,
    frequency: "month",
    benefits: [
      "PC in 1 game at a time",
      "DM for multiple adventures",
      "View last 10,000 messages",
    ],
  },
  {
    title: "Premium",
    cost: 2,
    frequency: "month",
    benefits: [
      "PC in multiple games at a time",
      "DM for multiple adventures",
      "View Unlimited messages",
      "Upload your own character avatars",
    ],
    isPurple: true,
    planId: "monthlyPrem",
  },
  {
    title: "Premium - Annual",
    cost: 20,
    frequency: "year",
    benefits: [
      "PC in multiple games at a time",
      "DM for multiple adventures",
      "View Unlimited messages",
      "Upload your own character avatars",
      "Get 2 months free!",
    ],
    isGreen: true,
    planId: "annualPrem",
  },
];

const SubscriptionList = () => {
  // eslint-disable-next-line no-empty-pattern
  const [{}, dispatch] = useKindeAuth();
  const successPayment = (data) => {
    console.log("Payment Successful", data);
    dispatch({
      type: "updateState",
      newState: { isPremium: true },
    });
    // update database
    // send email
    // redirect to home
  };

  const errorPayment = (data) => {
    console.log(data);
  };

  const onToken = (amount, description, planId) => (token) =>
    API.post("make_payment", {
      description,
      source: token.id,
      currency: CURRENCY,
      planId,
      amount: fromEuroToCent(amount),
    })
      .then(successPayment)
      .catch(errorPayment);

  return (
    <ul className="c-price-card-container">
      {plans.map((plan, i) => (
        <PriceCard key={i} {...plan} onToken={onToken} />
      ))}
    </ul>
  );
};

export default SubscriptionList;
