import React from "react";
import PropTypes from "prop-types";
import "./CopyText.css";

const CopyText = ({ children }) => (
  <div className="c-copy-text">{children}</div>
);

CopyText.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default CopyText;
