import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Button, Heading } from "./../../components";
import { useKindeAuth } from "kinde-auth-react";
import "./Header.css";
import { URLS } from "consts";

const Header = ({ handleLogout, title }) => {
  // eslint-disable-next-line no-empty-pattern
  const { logout } = useKindeAuth();

  return (
    <div className="l-header">
      <header className="c-header">
        <Link className="c-header__logo" to={URLS.home}>
          RPGo
        </Link>
        <Heading level={1} text={title} />
        <Button type="button" isSmall onClick={logout} text="Logout" />
      </header>
    </div>
  );
};

Header.propTypes = {
  handleLogout: PropTypes.func,
  title: PropTypes.string,
};

export default Header;
