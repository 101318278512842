/**
 * Everything related to querying the real DOM for elements. We need access to some of these elements, e.g.,
 * the root element, as some components need to manipulate them, such as the Dialog component.
 * @type {Object}
 */
const DOM = Object.freeze({
  root: document.documentElement,
  skipLinks: document.querySelector(".c-skip-links")
});

export default DOM;
