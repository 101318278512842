import React from "react";
import PropTypes from "prop-types";
import "./Panel.css";

const Panel = ({ children, isInfoText }) => (
  <div className={`c-panel ${isInfoText ? "c-panel--info-text" : null}`}>
    {children}
  </div>
);

Panel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  isInfoText: PropTypes.bool
};

export default Panel;
