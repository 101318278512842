import React from "react";
import PropTypes from "proptypes";
import Suggestion from "./../Suggestion";
import { commandFunctionMap } from "../../consts";
import "./AutoSuggest.css";

const findSuggestions = (value, characterName) => {
  const search = value.toLowerCase().substr(1);
  const characterType = () => (characterName === "DM" ? 1 : 2);

  return Object.keys(commandFunctionMap)
    .filter(key => {
      const isRestricted = () =>
        !commandFunctionMap[key].characterTypes.includes(characterType());

      return !isRestricted() ? key.includes(search) : false;
    })
    .map(match => commandFunctionMap[match]);
};

const AutoSuggest = ({ characterName, value }) => {
  if (value.charAt(0) !== "/" || value.includes(" ")) {
    return null;
  }

  const matches = findSuggestions(value, characterName);

  return (
    <ul className="c-autosuggest" role="listbox">
      {matches.map((item, i) => (
        <Suggestion
          key={i}
          {...item}
          title={item.command}
          description={item.desc}
        />
      ))}
    </ul>
  );
};

Suggestion.propTypes = {
  characterName: PropTypes.string,
  value: PropTypes.string
};

export default AutoSuggest;
