import React from "react";
import "./LoggedOutHeader.css";
import logo272 from "./rpgo-logo-272w.png";
import logo408 from "./rpgo-logo-408w.png";
import logo544 from "./rpgo-logo-544w.png";

const LoggedOutHeader = () => (
  <header>
    <h1 className="c-rpgo-logo">
      <span className="h-hide-visually">RPGo</span>
      <img
        className="c-rpgo-logo__image"
        srcSet={`${logo272},
             ${logo408} 1.5x, ${logo544} 2x`}
        height="272"
        width="95"
        src={logo544}
        alt="RPGo Logo"
      />
    </h1>
  </header>
);

export default LoggedOutHeader;
