import React from "react";
import ReactDOM from "react-dom";
// CSS Must come before the `Routes` where component overrides live
import "backpack.css";
import "css/index.css";
// should come last so helpers just win.
import "css/helpers.css";
// State
import { KindeProvider } from "./kinde-auth-react";
// Routing
import Routes from "Routes";
// Components
import { Icons } from "components";
import { createBrowserHistory } from "history";

const history = createBrowserHistory();

const onRedirectCallback = async (appState) => {
  history.replace((appState && appState.returnTo) || window.location.pathname);
};

ReactDOM.render(
  <React.StrictMode>
    <Icons />
    <KindeProvider
      domain={process.env.REACT_APP_KINDE_DOMAIN}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
    >
      <Routes />
    </KindeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
