import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "proptypes";
import { NewLineToParagraph, SideBySide, Spacing, Tag } from "components";
import "./AdventureListItem.css";
import { URLS } from "consts";

const AdventureListItem = ({ adventure }) => {
  const remainingSlots = Number(adventure.max_pcs) - Number(adventure.pc_count);
  return (
    <div className="c-adventure-list-item">
      <SideBySide isSpaceBetween>
        <h3 className="c-adventure-list-item__heading">{adventure.name}</h3>
        <span>
          {`${remainingSlots} `}
          slots left
        </span>
      </SideBySide>
      <Spacing size="small">
        <NewLineToParagraph content={adventure.description} />
      </Spacing>
      <div>
        {adventure.types &&
          adventure.types.map((type, i) => <Tag key={i} text={type.name} />)}
      </div>
      <Link
        className="c-overlay-button"
        to={`${URLS.joinAdventureRoot}/${adventure.id}`}
      >
        <span className="h-hide-visually">Join Adventure</span>
      </Link>
    </div>
  );
};

AdventureListItem.propTypes = {
  /**
   * An object containing adventure data
   */
  adventure: PropTypes.object
};

export default AdventureListItem;
