import React, { useState, useEffect } from "react";
import {
  Button,
  ButtonIconOnly,
  Dialog,
  Fieldset,
  Form,
  Heading,
  ListHeader,
  SelectList,
  SideBarListItem,
  Spacing
} from "./../../../../components";
import { API } from "./../../../../utils";

const CharacterEquipmentTab = ({ character, equipment, updateWeapon }) => {
  const [equipmentTypes, setEquipmentTypes] = useState([]);
  const [equipmentItems, setEquipmentItems] = useState([]);
  const [characterEquipment, setCharacterEquipment] = useState(equipment);
  const [equipmentLocations, setEquipmentLocations] = useState([]);
  const [currentGold, setCurrentGold] = useState(character.gold || 0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [newEquipment, setNewEquipment] = useState("");
  const [newEquipmentLocation, setNewEquipmentLocation] = useState([]);
  const [newSelectEquipmentType, setNewSelectEquipmentType] = useState("");

  const getData = async () => {
    const res = await API.get("equipment");
    setEquipmentTypes(res.data.data);
  };

  // get initial data
  useEffect(() => {
    getData();
  }, []);

  const switchEquipmentDropdown = async e => {
    const id = e.target.value;
    const res = await API.get(`equipment/${id}`);
    setEquipmentItems(res.data.data);
    // reset dropdowns
    setNewEquipment("");
    setEquipmentLocations([]);
    setNewSelectEquipmentType(id);
  };

  const switchLocationDropdown = async e => {
    const id = e.target.value;
    const res = await API.get(`equipment_location/${id}`);
    // reset dropdowns
    setNewEquipmentLocation("");
    setNewEquipment(id);
    setEquipmentLocations(res.data.data.concat({ id: 0, name: "Bag" }));
  };

  const deleteEquipmentItem = e => {
    e.preventDefault();
    const form = e.target;
    const { value } = form["equipment_id"];

    API.delete(`character_equipment/${value}`);
    setCharacterEquipment(
      characterEquipment.filter(i => i.id !== Number(value))
    );
  };

  const amendGold = amount => {
    const value = Number(currentGold) + Number(amount);
    setCurrentGold(value);
    API.post("update_gold", {
      id: character.id,
      currentGold: value
    });
  };

  const addEquipment = async e => {
    e.preventDefault();
    const select = e.target.new_equipment;
    const new_location = Number(e.target.new_equipment_location.value);
    const { value } = select;
    const new_name = select[select.selectedIndex].innerHTML;

    const res = await API.post("character_equipment", {
      id: character.id,
      new_equipment: Number(value),
      new_location,
      new_name
    });

    //update old item if at same location as new one
    const newState = characterEquipment.map(item =>
      Number(item.character_body_map_id) === new_location
        ? { ...item, character_body_map_id: 0 }
        : item
    );

    if (new_location === 1 || new_location === 2) {
      updateWeapon(res.data);
    }

    setCharacterEquipment([...newState, res.data]);
  };

  return (
    <>
      <SideBarListItem label="Gold" value={currentGold} amendStat={amendGold} />

      <ListHeader>
        <Heading text="Equipment" />
        <ButtonIconOnly
          onClick={() => setIsModalOpen(true)}
          text="Add item"
          icon="id_plus_icon"
        />
      </ListHeader>

      <ul className="c-equipment-list">
        {characterEquipment.map((item, i) => (
          <li key={i} className="c-equipment">
            <div className="c-equipment__name">
              {item.name}{" "}
              {Boolean(item.character_body_map_id) && (
                <span className="c-tag">Equipped</span>
              )}
            </div>
            <div className="c-equipment">
              <div className="c-equipment__value">{item.weight}lb</div>
              <Form onSubmit={deleteEquipmentItem}>
                <input type="hidden" name="equipment_id" value={item.id} />
                <ButtonIconOnly
                  icon="id_trash_icon"
                  type="submit"
                  text="Delete Equipment"
                />
              </Form>
            </div>
          </li>
        ))}
      </ul>

      <Dialog
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel="Password Dialog"
        title="Add equipment"
      >
        <Form onSubmit={addEquipment} className="h-spacing-large">
          <Fieldset title="">
            <Spacing>
              <SelectList
                id="id_select_equipment_type"
                labelText="Type"
                name="new_select_equipment_type"
                value={newSelectEquipmentType}
                onChange={switchEquipmentDropdown}
                options={equipmentTypes}
                placeholder="Select type"
              />
            </Spacing>
            <Spacing>
              <SelectList
                id="id_select_equipment_item"
                labelText="Item"
                name="new_equipment"
                value={newEquipment}
                disabled={equipmentItems.length === 0}
                options={equipmentItems}
                onChange={switchLocationDropdown}
                placeholder="Select item"
                required
              />
            </Spacing>
            {equipmentLocations.length > 1 ? (
              <Spacing>
                <SelectList
                  id="id_equipment_location_item"
                  labelText="Equip"
                  name="new_equipment_location"
                  value={newEquipmentLocation}
                  options={equipmentLocations}
                  placeholder="Location"
                  onChange={setNewEquipmentLocation}
                  required
                />
              </Spacing>
            ) : (
              <input
                type="hidden"
                onChange={setNewEquipmentLocation}
                name="new_equipment_location"
                value={newEquipmentLocation}
              />
            )}

            <Button text="Add Item" />
          </Fieldset>
        </Form>
      </Dialog>
    </>
  );
};

export default CharacterEquipmentTab;
