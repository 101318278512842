import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { API } from "utils";
import AdventureDetailsForm from "pages/AdventureDetailsForm";
import { Container, CopyText, Header, Panel, Spacing } from "components";
import { URLS } from "consts";

const CreateAdventure = () => {
  const [channelId, setChannelId] = useState();
  const [adventureId, setAdventureId] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async formState => {
    try {
      setIsSubmitting(true);
      const res = await API.post("adventures", formState);
      setChannelId(res.data.channelId);
      setAdventureId(res.data.adventureId);
    } catch (err) {
      setIsSubmitting(false);
      console.log(err);
    }
  };

  if (adventureId > 0) {
    return (
      <Redirect to={`${URLS.adventureRoot}/${adventureId}/${channelId}`} />
    );
  }

  return (
    <>
      <Header title="Create Adventure" />
      <Container isCentered>
        <Spacing>
          <Panel isInfoText>
            <CopyText>
              <p>
                As creator of an Adventure you will be assigned as the Dungeon
                Master for it.
              </p>
              <p>
                If you're not looking to DM you can always{" "}
                <Link to={`${URLS.home}/#find-adventure`}>
                  find a game to join
                </Link>
                .
              </p>
            </CopyText>
          </Panel>
        </Spacing>
        <Panel>
          <AdventureDetailsForm
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
          />
        </Panel>
      </Container>
    </>
  );
};

export default CreateAdventure;
