import React from "react";
import { Icon } from "./../../components";
import "./Loading.css";

const Loading = () => (
  <div className="c-loading">
    <span className="h-hide-visually">Loading&hellip;</span>
    <Icon id="id_d20_icon" className="c-loading__spinner" />
  </div>
);

export default Loading;
