import React, { useEffect, useState, memo } from "react";
import PropTypes from "proptypes";
import classNames from "classnames";
import { getCommandString, getCommandParam } from "./../../utils";
import "./Message.css";
import { DateFormat, NewLineToParagraph } from "./../../components";

const Message = ({
  author,
  avatar,
  channelType,
  character_name,
  content,
  created_on,
  id,
  type,
  username
}) => {
  const [shouldScroll, setShouldScroll] = useState(
    () => document.body.scrollHeight <= window.scrollY + window.innerHeight
  );

  // componentDidMount
  useEffect(() => {
    if (shouldScroll) {
      window.scrollTo(0, document.body.scrollHeight);
      setShouldScroll(false);
    }
  }, [shouldScroll]);

  const classes = classNames({
    "c-message": true,
    [`c-message--${type}`]: type && (character_name !== "DM" || type === "npc"),
    "c-message--dm": type !== "npc" && character_name === "DM"
  });

  const oocCLasses = classNames({
    "c-message": true,
    "c-message--ooc": true,
    "c-message--current-user": author === username
  });

  return (
    <li id={`id_message_${id}`}>
      {channelType !== 2 ? (
        <div className="c-message-container">
          {character_name !== "DM" && type === "message" ? (
            <div className="c-message__image">
              <img
                src={`${process.env.PUBLIC_URL}/images/${avatar}`}
                alt={`Avatar for ${character_name}`}
              />
            </div>
          ) : null}

          <div className={classes}>
            {(type === "message" || type === "npc") && (
              <div className="c-message__header">
                <DateFormat isHidden timestamp={created_on} />
                <strong>
                  {type !== "npc" ? character_name : getCommandParam(content)}
                </strong>
              </div>
            )}
            <div className="c-message__message">
              {type !== "message" && type !== "npc" && (
                <strong>{character_name} </strong>
              )}
              <NewLineToParagraph
                content={type !== "npc" ? content : getCommandString(content)}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="c-message-container">
          <div className={oocCLasses}>
            <div className="c-message__header">
              <strong>{author}</strong>&nbsp;<span>({character_name})</span>
            </div>
            <div className="c-message__message">{content}</div>
          </div>
        </div>
      )}
    </li>
  );
};

Message.propTypes = {
  author: PropTypes.string,
  avatar: PropTypes.string,
  channelType: PropTypes.number,
  character_name: PropTypes.string,
  content: PropTypes.string,
  created_on: PropTypes.string,
  id: PropTypes.number,
  type: PropTypes.string,
  username: PropTypes.string
};

function areEqual(prevProps, nextProps) {
  /*
  return true if passing nextProps to render would return
  the same result as passing prevProps to render,
  otherwise return false
  */
  return prevProps.id === nextProps.id;
}

export default memo(Message, areEqual);
