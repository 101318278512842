import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { RadioButton, Spacing } from "./../../components";
import "./RadioButtonGroup.css";

const RadioButtonGroup = ({ data, isInline, selectedId, title, ...props }) => {
  const classes = classNames({
    "c-radio-button-group": true,
    "c-radio-button-group--inline": isInline
  });
  return (
    <fieldset>
      <Spacing size="x-small">
        <legend>
          <span className="c-input-field__label">{title}</span>
        </legend>
      </Spacing>
      <div className={classes}>
        {data.map((data, i) => (
          <RadioButton
            {...props}
            checked={selectedId === data.id}
            key={i}
            idx={i}
            labelText={data.name}
            value={data.id}
          />
        ))}
      </div>
    </fieldset>
  );
};

RadioButtonGroup.propTypes = {
  data: PropTypes.array,
  isInline: PropTypes.bool,
  selectedId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string
};

export default RadioButtonGroup;
