import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./Heading.css";

const Heading = ({ isColored, level = 2, spacing, tag, text }) => {
  let Tag = tag ? tag : `h${level}`;
  const classes = classNames({
    "c-heading": true,
    "c-heading--alt": isColored,
    [`h-spacing-${spacing}`]: spacing
  });

  return <Tag className={classes}>{text}</Tag>;
};

Heading.propTypes = {
  isColored: PropTypes.bool,
  level: PropTypes.number,
  spacing: PropTypes.string,
  tag: PropTypes.string,
  text: PropTypes.string
};

export default Heading;
