import React from "react";
import PropTypes from "proptypes";
import { Link } from "react-router-dom";
import "./HomePanelItem.css";
import { URLS } from "consts";
import { NotificationCount } from "components";
const HomePanelItem = ({ data, url }) => {
  const IMG_SIZE = 144;
  return (
    <li className="c-home-panel__item">
      <h2 className="c-home-panel__item--name h-spacing-large">{data.name}</h2>
      <img
        className="c-home-panel__item--image"
        height={IMG_SIZE}
        width={IMG_SIZE}
        src={`${process.env.PUBLIC_URL}/images/${
          data.character_name === "DM" ? "portraits/dm.jpg" : data.avatar
        }`}
        alt={`Avatar for ${data.character_name}`}
      />
      <dl>
        <dt className="c-home-panel__item--title">Playing as</dt>

        <dd className="c-home-panel__item--value">{data.character_name}</dd>
      </dl>
      {data.message_count > 0 && (
        <NotificationCount count={data.message_count} />
      )}
      <Link
        className="h-cover-button"
        to={`${URLS.app}/${url}/${data.id}/${data.primary_channel_id}`}
      />
    </li>
  );
};

HomePanelItem.propTypes = {
  data: PropTypes.object,
  url: PropTypes.string
};

export default HomePanelItem;
