import React from "react";
import { Heading, SkillItem, Spacing } from "./../../../../components";

const CharacterSpellsTab = ({ rollStat, spells }) => {
  return (
    <Spacing>
      <Heading text="Spells" />
      <ul>
        {spells.map((s, i) => (
          <SkillItem
            key={i}
            name={s.name}
            rollStat={rollStat}
            value={s.value}
          />
        ))}
      </ul>
    </Spacing>
  );
};

export default CharacterSpellsTab;
