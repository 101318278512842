import React from "react";
import PropTypes from "prop-types";
import "./ErrorMessage.css";

const ErrorMessage = ({ id, text }) => {
  return (
    text && (
      <p id={id} className="c-error-message">
        {text}
      </p>
    )
  );
};

ErrorMessage.propTypes = {
  id: PropTypes.string,
  text: PropTypes.string
};

export default ErrorMessage;
