import React from "react";
import PropTypes from "prop-types";
import { Container, LoggedOutHeader } from "./../../components";

const LoggedOutLayout = ({ children }) => (
  <Container isCentered>
    <LoggedOutHeader />
    <Container isFixedWidth isCentered>
      <main>{children}</main>
    </Container>
  </Container>
);

LoggedOutLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default LoggedOutLayout;
