import React from "react";
import { Link } from "react-router-dom";
import { useKindeAuth } from "kinde-auth-react";
import { useDataApi } from "hooks";
import { Container, Header, TabSet } from "components";
import { API } from "utils";
import FindAdventure from "./FindAdventure";
import YourAdventures from "./YourAdventures";
import Invitations from "./Invitations";
import UserSettings from "./UserSettings";
import { URLS } from "consts";

const Home = () => {
  const { user } = useKindeAuth();
  console.log("here");
  const { data, isLoading, doGet } = useDataApi("user_invitations", []);

  const declineInvitation = async (e) => {
    try {
      await API.put(`decline_invite/${e.target.value}`);
      doGet();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Header title="Home" />

      <Container isCentered>
        <div className="l-flex-space-between">
          <p>Hey {user.first_name}</p>

          <Link
            to={URLS.createAdventure}
            className="c-button c-button--secondary"
          >
            Create Adventure
          </Link>
        </div>
        <TabSet
          data={[
            {
              href: "your-adventures",
              label: "Your adventures",
              icon: "id_sword_icon",
              content: <YourAdventures />,
            },
            {
              href: "find-adventure",
              label: "Find adventure!",
              icon: "id_search_icon",
              content: <FindAdventure />,
            },
            {
              href: "invitations",
              label: "Invitations",
              icon: "id_raven_icon",
              count: data.length,
              content: (
                <Invitations
                  invitations={data}
                  handleDelete={declineInvitation}
                  isLoading={isLoading}
                />
              ),
            },
            {
              href: "settings",
              label: "Settings",
              icon: "id_cog_icon",
              content: <UserSettings />,
            },
          ]}
          defaultHref="your-adventures"
          isResponsive={true}
        />
      </Container>
    </>
  );
};

export default Home;
