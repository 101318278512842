import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./ButtonLink.css";

const ButtonLink = ({ isBold, text, type = "button", ...props }) => {
  const classes = classNames({
    "c-button-link": true,
    "c-button-link--bold": isBold
  });
  return (
    <button className={classes} type={type} {...props}>
      {text}
    </button>
  );
};

ButtonLink.propTypes = {
  isBold: PropTypes.bool,
  text: PropTypes.string,
  type: PropTypes.string
};

export default ButtonLink;
