import React from "react";
import PropTypes from "prop-types";

const TabPane = ({ children, id, isSelected }) => {
  return (
    <section
      className={`c-tabset__content ${isSelected ? "is-active" : ""}`}
      id={id}
      role="tabpanel"
      aria-hidden={!isSelected}
    >
      {children}
    </section>
  );
};

TabPane.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  id: PropTypes.string,
  isSelected: PropTypes.bool
};

export default TabPane;
