import React from "react";
import PropTypes from "prop-types";
import { Link, NavLink } from "react-router-dom";
import {
  ButtonIconOnly,
  Container,
  Heading,
  SideBar,
  Spacing,
  NotificationCount
} from "components";
import "./AdventureLeftSidebar.css";
import { URLS } from "consts";

const LeftSideBar = ({
  adventureId,
  adventures,
  channels = [],
  hideSidebar,
  isVisible
}) => (
  <SideBar isVisible={isVisible} side="left">
    <Container>
      <Spacing size="2x-large">
        <ButtonIconOnly
          icon="id_close_icon"
          onClick={hideSidebar}
          text="Close sidebar"
        />
      </Spacing>

      <Heading text="Channels" spacing="large" />

      <ul className="c-sidebar-link-container">
        {channels.map(channel => (
          <li key={channel.id}>
            <NavLink
              className="c-sidebar__link"
              onClick={hideSidebar}
              to={`${URLS.adventureRoot}/${adventureId}/${channel.id}`}
            >
              {channel.name}
              {channel.message_count > 0 && (
                <NotificationCount
                  count={channel.message_count}
                  position="middle-right"
                />
              )}
            </NavLink>
          </li>
        ))}
      </ul>

      <Link className="c-exit-link" to={URLS.home}>
        Exit to main menu
      </Link>
    </Container>
  </SideBar>
);

LeftSideBar.propTypes = {
  adventureId: PropTypes.number,
  adventures: PropTypes.node,
  channels: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string
    })
  ),
  hideSidebar: PropTypes.func,
  isVisible: PropTypes.bool
};

export default LeftSideBar;
