import React from "react";
import PropTypes from "prop-types";
import "./ListHeader.css";

const ListHeader = ({ children }) => (
  <div className="c-list-header">{children}</div>
);

ListHeader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default ListHeader;
