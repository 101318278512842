import React, { useState, useEffect } from "react";
import PropTypes from "proptypes";
import { Link } from "react-router-dom";
import { API, toggleArrayItem } from "utils";
import {
  Button,
  ButtonGroup,
  CheckBoxTags,
  Fieldset,
  Form,
  InputField,
  InvitePlayer,
  Loading,
  RadioButtonGroup,
  SelectList,
  Spacing
} from "components";
import { URLS } from "consts";

const AdventureDetailsForm = ({ handleSubmit, isSubmitting }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [types, setTypes] = useState();
  const [newUsersToInvite, setNewUsersToInvite] = useState([]);
  const [showMaxPCsErrorText, setShowMaxPCsErrorText] = useState();
  const [newMaxPCs, setNewMaxPCs] = useState(4);
  const [values, setValues] = useState({
    new_name: "",
    new_adventure_status: "1",
    new_types: [],
    new_description: "",
    new_users_to_invite: []
  });

  const getData = async () => {
    const res = await API.get("get_dictionary/T1");
    setTypes(res.data.data || []);
    setIsLoading(false);
  };

  // componentDidMount
  useEffect(() => {
    getData();
  }, []);

  const updateUsersToInvite = value => {
    setNewUsersToInvite(value.map(i => i.email));
  };

  const handleChange = e => {
    const value =
      e.target.type === "checkbox"
        ? toggleArrayItem(values[e.target.name], Number(e.target.value))
        : e.target.value;

    setValues({ ...values, [e.target.name]: value });
  };

  const handleSelectChange = e => {
    const { value } = e.target;
    value < newUsersToInvite
      ? setShowMaxPCsErrorText(true)
      : setNewMaxPCs(value);
  };

  const onSubmit = e => {
    e.preventDefault();
    handleSubmit({
      ...values,
      new_max_pcs: newMaxPCs,
      new_users_to_invite: newUsersToInvite
    });
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Form autoComplete="off" onSubmit={onSubmit}>
      <Fieldset title="Details">
        <Spacing>
          <InputField
            name="new_name"
            onChange={handleChange}
            value={values.new_name}
            labelText="Adventure name"
            required
          />
        </Spacing>
        <Spacing>
          <SelectList
            labelText="Max. player characters"
            name="new_max_pcs"
            value={newMaxPCs}
            onChange={handleSelectChange}
            options={[
              {
                id: 1,
                name: "1"
              },
              {
                id: 2,
                name: "2"
              },
              {
                id: 3,
                name: "3"
              },
              {
                id: 4,
                name: "4"
              },
              {
                id: 5,
                name: "5"
              },
              {
                id: 6,
                name: "6"
              }
            ]}
            required
          />
          {showMaxPCsErrorText && (
            <p>
              Hint: You can't change this to less than the current number of
              invited players
            </p>
          )}
        </Spacing>

        <InputField
          isMultiline
          name="new_description"
          onChange={handleChange}
          value={values.new_description}
          labelText="Description"
        />
      </Fieldset>
      <Spacing />
      <Spacing size="2x-large">
        <CheckBoxTags
          data={types}
          isInline
          name="new_types"
          onChange={handleChange}
          selectedItems={values.new_types}
          title="Tags"
        />
      </Spacing>
      <Spacing size="2x-large">
        <InvitePlayer
          playerCount={Number(newMaxPCs) || null}
          handleChange={updateUsersToInvite}
        />
      </Spacing>
      <Spacing>
        <RadioButtonGroup
          data={[
            { id: "1", name: 'Public - Discoverable via "Find Adventure"' },
            { id: "2", name: "Private - Only available to people I invite" }
          ]}
          name="new_adventure_status"
          onChange={handleChange}
          selectedId={values.new_adventure_status}
          title="Fill available player slots"
        />
      </Spacing>
      <ButtonGroup isSpaceBetween>
        <Link to={URLS.home}>Cancel</Link>
        <Button text="Create" isLoading={isSubmitting} />
      </ButtonGroup>
    </Form>
  );
};

AdventureDetailsForm.propTypes = {
  handleSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool
};

export default AdventureDetailsForm;
