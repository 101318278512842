import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Fieldset,
  Form,
  InputField,
  Spacing
} from "./../../components";

const ChangePasswordForm = ({
  buttonText,
  description,
  handleChange,
  handleSubmit,
  inputLabelText,
  title,
  value
}) => (
  <Form onSubmit={handleSubmit}>
    <Spacing>
      <Fieldset title={title}>
        <Spacing>
          <p>{description}</p>
        </Spacing>
        <InputField
          name="new_password"
          onChange={handleChange}
          value={value}
          labelText={inputLabelText}
          type="password"
        />
      </Fieldset>
    </Spacing>
    <Spacing>
      <Button isFullWidth text={buttonText} />
    </Spacing>
  </Form>
);

ChangePasswordForm.propTypes = {
  buttonText: PropTypes.string,
  description: PropTypes.string,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  inputLabelText: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.string
};

export default ChangePasswordForm;
