import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Icon, NotificationCount } from "components";

const Tab = ({
  count = 0,
  label,
  href,
  handleClick,
  handleKeyPress,
  isResponsive,
  isSelected,
  icon,
  index
}) => {
  const labelClasses = classNames({
    "h-hide-visually": !isResponsive,
    "h-hide-visually-palm": isResponsive,
    "c-tabset__tab-label": true
  });
  return (
    <li className="c-tabset__item" role="presentation">
      <a
        data-index={index}
        className={`c-tabset__tab ${isSelected ? "is-active" : ""}`}
        onKeyDown={handleKeyPress}
        onClick={handleClick}
        href={`#${href}`}
        tabIndex={isSelected ? "0" : "-1"}
        role="tab"
        aria-controls={href}
        aria-selected={isSelected}
      >
        <Icon id={icon} className="c-tabset__tab-icon" />
        <span className={labelClasses}>{label}</span>
        {count > 0 && <NotificationCount count={count} />}
      </a>
    </li>
  );
};

Tab.propTypes = {
  label: PropTypes.string,
  href: PropTypes.string,
  handleClick: PropTypes.func,
  handleKeyPress: PropTypes.func,
  isSelected: PropTypes.bool,
  icon: PropTypes.string,
  index: PropTypes.number
};

export default Tab;
