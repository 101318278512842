import { Redirect } from "react-router-dom";
import { Button, Panel, Spacing } from "components";
import { LoggedOutLayout } from "layout";
import { useKindeAuth } from "./../../kinde-auth-react";

import { URLS } from "consts";

const Landing = (props) => {
  const { login, register, isLoading, isAuthenticated, error } = useKindeAuth();

  const handleLogin = async () => {
    await login();
  };

  const handleRegister = async () => {
    await register();
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    console.log(error);
    return <div>Oops... {error.message}</div>;
  }

  if (isAuthenticated) {
    const to = props.location.state ? props.location.state.to : "";
    const url = to === URLS.login || to === "" ? URLS.home : to;
    return <Redirect to={url} />;
  } else {
    return (
      <LoggedOutLayout>
        <Spacing>
          <Panel>
            <Button isFullWidth text="Login" onClick={handleLogin} />
            <p>or</p>
            <Button
              isSecondary
              isFullWidth
              text="Create account"
              onClick={handleRegister}
            />
          </Panel>
        </Spacing>
      </LoggedOutLayout>
    );
  }
};

export default Landing;
