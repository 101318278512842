import React from "react";
import PropTypes from "prop-types";
import "./OverlayButton.css";

const OverlayButton = ({ text, ...props }) => (
  <button {...props} className="c-overlay-button" type="button">
    <span className="h-hide-visually">{text}</span>
  </button>
);

OverlayButton.propTypes = {
  text: PropTypes.string
};

export default OverlayButton;
